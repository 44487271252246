import { createRoot } from 'react-dom/client';
import '@bookla-app/bookla-react-components/dist/main.css';
import './index.scss';
import { App } from './app';
import reportWebVitals from './reportWebVitals';
import { analytics } from 'utils/analytics';
import { isInIframe } from 'utils/isInIframe';
import {
  polyfillIntl,
  SUPPORTED_LANGUAGES,
} from '@bookla-app/bookla-react-components';
import { logger } from 'utils/logger';

polyfillIntl(SUPPORTED_LANGUAGES).catch((error) => {
  logger.error(error);
});

const init = () => {
  const rootEl = document.getElementById('root');

  if (!isInIframe() && rootEl) {
    document.documentElement.classList.add('not-iframe');
  }

  const root = createRoot(rootEl!);
  root.render(<App />);
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(analytics.performance);
