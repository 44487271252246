import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Route, Routes, useParams} from "react-router-dom";
import {NotFoundPage} from "../../pages/notFoundPage";
import classNames from "classnames";
import {
  Button,
  ButtonSize,
  CalendarIcon,
  CommentEmptyIcon,
  CreditCardIcon,
  DirectionIcon,
  DiscountIcon,
  DiscountRoundIcon,
  formatCurrency,
  IconButton,
  IconTheme,
  LadyWithShoppingErrorIcon,
  ListItem,
  PersonIcon,
  PhoneIcon,
  Spinner,
  StoreIcon,
  sum,
  Thumbnail,
  useRequest
} from "@bookla-app/bookla-react-components";
import {getErrorMessage} from "../../../utils/getRequestError";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {reservationApi} from "../../../api/reservation";
import {useAppState} from "../../../contexts/AppStateProvider";
import {Header} from "../../molecules/header";
import {ReservationDetails, ReservationStatus} from "../../../types/reservation";
import {ExternalLink} from "../../atoms/ExternalLink";
import {
  getExternalServiceLink,
  getGoogleCalendarCreateEventLink,
  getGoogleMapsLink
} from "../../../utils/externalLinks";
import {isReservationNotCancellable} from "../../../utils/reservations";
import {ScheduleType} from "../../../types/schedule";
import {parsePromoCode} from "../../../utils/parsePromoCode";
import {ProductType} from "../../../types/product";
import {capitalise} from "../../../utils/text";
import {getPromoNameTranslation} from "../../../utils/productMappings";
import {EmptyPrice} from "../../molecules/emptyPrice";
import {widgetOptions} from "../../../utils/widgetOptions";
import {getPayedOptionsTranslation} from "../../../utils/paymentType";
import {Modal} from "../../molecules/modal";
import {useTicketBundleDetails} from "../../../utils/useTicketBundleDetails";
import {useModalState} from "../../../utils/useModalState";

interface CancelReservationProps {
  intl: IntlShape;
}

export const CancelReservationPage: React.FC<CancelReservationProps> = ({
  intl,
}) => {
  const reservationId = useParams<{ reservationId?: string }>().reservationId;
  const sign = useParams<{ sign?: string }>().sign;
  const [pageError, setPageError] = useState<string>();
  const [reservation, setReservation] = useState<ReservationDetails | undefined>(undefined);
  const [finished, setFinished] = useState(false);

  const getData = useCallback(async () => {
    if (!reservationId || !sign) {
      return undefined;
    }
    return reservationApi
      .getReservationInfoToCancel(reservationId, sign)
  }, [reservationId, sign]);

  const onError = useCallback(
    (error: unknown) => {
      setPageError(getErrorMessage(error, intl));
    },
    [intl, setPageError]
  );

  const [cancelling, setIsCancelling] = useState(false);

  const [isLoading] = useRequest({
    getData,
    onSuccess: setReservation,
    locale: intl.locale,
    onError,
  });

  const { isModalOpen, openModal, closeModal } = useModalState();

  const companyPhone = useMemo(
    () =>
      reservation?.company.contacts.find((contact) => contact.type === 'phone')
        ?.value,
    [reservation?.company.contacts]
  );

  const lastComment = useMemo(
    () =>
      reservation?.history.filter(
        (h) => h.clientComment || h.companyComment
      )[0],
    [reservation?.history]
  );

  const { ticketBundleDetails } = useTicketBundleDetails({
    companyId: reservation?.company.id ?? '',
    ticketsBundleId: reservation?.ticketsBundleId,
  });

  const closeAndCancel = useCallback(() => {
    closeModal();
    if (reservationId && sign) {
      setIsCancelling(true);
      reservationApi.cancelReservationWithSign(reservationId, sign)
        .then(() => {
          setIsCancelling(false);
          setFinished(true);
      });
    }
  }, [closeModal, reservationId, sign]);

  const footer = useMemo(() => {
    if (!reservation) {
      return null;
    }
    if (isReservationNotCancellable(reservation)) {
      if (!companyPhone) {
        return null;
      }
      return (
        <ExternalLink
          type="button"
          size={ButtonSize.sm}
          href={`tel:${companyPhone}`}
        >
          {intl.formatMessage({
            id: 'my_reservations_modal_contact_company_cta',
          })}
        </ExternalLink>
      );
    }

    if (
      [
        ReservationStatus.Pending,
        ReservationStatus.PendingPayment,
        ReservationStatus.Confirmed,
        ReservationStatus.Paid,
      ].includes(reservation.status)
      && !finished
    ) {
      return (
        <Button
          onClick={() => openModal()}
          className="w-full"
          size="sm"
        >
          {intl.formatMessage({ id: 'my_reservations_modal_cancel_cta' })}
        </Button>
      );
    }

    return (
      <ExternalLink
        type="button"
        size={ButtonSize.sm}
        href={getExternalServiceLink(
          reservation.company.id,
          reservation.service.id
        )}
      >
        {intl.formatMessage({
          id: 'my_reservations_modal_book_again_cta',
        })}
      </ExternalLink>
    );
  }, [companyPhone, intl, reservation, openModal, finished]);

  useEffect(() => {
    setPageError(undefined);
  }, [setPageError]);

  return (
    <div
      className={classNames(
        'page page-sm',
      )}
    >
      <Header />
      <main className="flex flex-col flex-grow px-5 pb-5 md:px-10">
        {(isLoading || cancelling) && <Spinner/>}
        {!isLoading && !!pageError && (
          <div className="flex flex-col items-center my-auto">
            <LadyWithShoppingErrorIcon
              fullSize
              className="max-w-sm max-h-96 -mt-8 -mb-4"
            />
            <p className="text-center font-semibold">{pageError}</p>
          </div>
        )}
        {!!reservation && (
          <>
            <h1 className="font-semibold text-xl mb-1">
              {finished || reservation.status === ReservationStatus.Cancelled ? (
                <FormattedMessage id="reservation_status_cancelled" />
              ) : (
                  <>
                  {reservation.service.title}
                  </>
                )}
            </h1>
            <div className="overflow-y-auto overflow-x-hidden">
              <ListItem
                title={`${intl.formatDate(reservation.startDate, {
                  day: 'numeric',
                  month: 'short',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZone: reservation.timeZone,
                })} - ${intl.formatDate(reservation.endDate, {
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZone: reservation.timeZone,
                })}`}
                subTitle={
                reservation.status == ReservationStatus.Confirmed && !finished && (
                  <ExternalLink
                    href={getGoogleCalendarCreateEventLink({
                      serviceName: reservation.service.title,
                      startDate: reservation.startDate,
                      endDate: reservation.endDate,
                      latitude: reservation.company.latitude,
                      longitude: reservation.company.longitude,
                    })}
                    type="link"
                  >
                    {intl.formatMessage({
                      id: 'my_reservations_modal_reservation_add_calendar',
                    })}
                  </ExternalLink>
                  )
                }
                leftComponent={
                  <CalendarIcon fill={IconTheme.Primary} className="w-5 h-5"/>
                }
                centerItems
                withDivider
                withPaddingY
              />
              {reservation.type === ScheduleType.Slot && (
                <ListItem
                  title={intl.formatMessage(
                    {id: 'my_reservations_modal_booked_tickets'},
                    {
                      total: reservation.tickets
                        ? sum(reservation.tickets.map((ticket) => ticket.spots))
                        : reservation.spots?.taken ?? 0,
                    }
                  )}
                  subTitle={
                    <>
                      {reservation.tickets?.map((ticket) => (
                        <span key={ticket.id} className="block">
                        {ticket.title} x{ticket.spots} -{' '}
                          {formatCurrency({
                            currency: ticket.currency,
                            value: ticket.price,
                            intl,
                          })}
                      </span>
                      ))}
                      {!reservation.tickets?.length &&
                        !!reservation.spots &&
                        `${intl.formatMessage({
                          id: 'my_reservations_modal_total_spots',
                        })} - ${reservation.spots.total}`}
                    </>
                  }
                  withDivider
                  withPaddingY
                  leftComponent={
                    <PersonIcon fill={IconTheme.Primary} className="w-5 h-5"/>
                  }
                />
              )}
              <ListItem
                title={reservation.company.title}
                subTitle={reservation.company.address}
                leftComponent={
                  <StoreIcon fill={IconTheme.Primary} className="w-5 h-5"/>
                }
                withPaddingY
              />
              <div className="flex flex-nowrap justify-between items-center px-4 sm:px-10 pb-5 border-b border-divider">
                {!!companyPhone && (
                  <>
                    <ExternalLink type="none" href={`tel:${companyPhone}`}>
                      <IconButton
                        icon={PhoneIcon}
                        ariaLabel={intl.formatMessage({
                          id: 'my_reservations_modal_call_company',
                        })}
                        showShadow
                        padding="p-4"
                        size="xl"
                      />
                    </ExternalLink>
                    <ExternalLink type="none" href={`sms:${companyPhone}`}>
                      <IconButton
                        icon={CommentEmptyIcon}
                        ariaLabel={intl.formatMessage({
                          id: 'my_reservations_modal_text_company',
                        })}
                        showShadow
                        padding="p-4"
                        size="xl"
                      />
                    </ExternalLink>
                  </>
                )}
                <ExternalLink
                  type="none"
                  href={getGoogleMapsLink(
                    reservation.company.latitude,
                    reservation.company.longitude
                  )}
                >
                  <IconButton
                    icon={DirectionIcon}
                    ariaLabel={intl.formatMessage({
                      id: 'my_reservations_modal_find_company',
                    })}
                    showShadow
                    padding="p-4"
                    size="xl"
                  />
                </ExternalLink>
              </div>
              <ListItem
                title={reservation.calendar.title}
                withPaddingY
                withDivider
                leftComponent={
                  <Thumbnail
                    type="image"
                    url={reservation.calendar.thumbnail}
                    size="xs"
                    shape="circle"
                  />
                }
                centerItems
              />
              {!!ticketBundleDetails && (
                <ListItem
                  title={ticketBundleDetails.title}
                  subTitle={ticketBundleDetails.description}
                  withPaddingY
                  withDivider
                  leftComponent={
                    <DiscountRoundIcon
                      fill={IconTheme.Primary}
                      className="w-5 h-5"
                    />
                  }
                  centerItems
                />
              )}
              {!!reservation.promoCode && (
                <ListItem
                  title={parsePromoCode(reservation.promoCode, intl).discountText}
                  subTitle={
                    reservation.promoCode.productType === ProductType.Code
                      ? reservation.promoCode.code
                      : capitalise(
                        getPromoNameTranslation(
                          reservation.promoCode.productType,
                          intl
                        )
                      )
                  }
                  withPaddingY
                  withDivider
                  leftComponent={
                    <DiscountIcon fill={IconTheme.Primary} className="w-5 h-5"/>
                  }
                  centerItems
                />
              )}
              {reservation.payment ? (
                <>
                  <ListItem
                    title={intl.formatMessage({
                      id: 'my_reservations_modal_slot_paid_amount',
                    })}
                    subTitle={intl.formatDate(reservation.payment.chargedAt, {
                      dateStyle: 'medium',
                    })}
                    withPaddingY
                    leftComponent={
                      <CreditCardIcon
                        fill={IconTheme.Primary}
                        className="w-5 h-5"
                      />
                    }
                    rightComponent={
                      <span className="font-medium">
                      {formatCurrency({
                        currency: reservation.payment.currency,
                        value: reservation.payment.totalAmount,
                        intl,
                      })}
                    </span>
                    }
                  />
                  {reservation.payment.refundedAt && (
                    <>
                      <ListItem
                        title={intl.formatMessage({
                          id: 'my_reservations_modal_slot_refunded_amount',
                        })}
                        subTitle={intl.formatDate(
                          reservation.payment.refundedAt,
                          {
                            dateStyle: 'medium',
                          }
                        )}
                        className="pb-5"
                        leftComponent={<span className="w-5"></span>}
                        rightComponent={
                          <span className="font-medium">
                          {formatCurrency({
                            currency: reservation.payment.currency,
                            value: reservation.payment.refundAmount,
                            intl,
                          })}
                        </span>
                        }
                      />
                      <ListItem
                        title={intl.formatMessage({
                          id: 'my_reservations_modal_slot_adjusted_amount',
                        })}
                        subTitle={intl.formatMessage({
                          id: 'my_reservations_modal_slot_adjusted_amount_description',
                        })}
                        withSubtitleEllipsis={false}
                        className="pb-5"
                        leftComponent={<span className="w-5"></span>}
                        rightComponent={
                          <span className="font-medium">
                          {formatCurrency({
                            currency: reservation.payment.currency,
                            value:
                              reservation.payment.totalAmount -
                              reservation.payment.refundAmount,
                            intl,
                          })}
                        </span>
                        }
                      />
                    </>
                  )}
                  <hr/>
                </>
              ) : (
                <EmptyPrice showPrice={!widgetOptions.hideServicePrices} content={
                  <ListItem
                    title={
                      <>
                        {formatCurrency({
                          currency: reservation.currency,
                          value: reservation.price,
                          intl,
                        })}
                        {reservation.price !== reservation.originalPrice && (
                          <span className="line-through ml-1">
                          {formatCurrency({
                            currency: reservation.currency,
                            value: reservation.originalPrice,
                            intl,
                          })}
                        </span>
                        )}{' '}
                        {getPayedOptionsTranslation(
                          reservation.company.paymentOptionsType,
                          intl
                        )}
                      </>
                    }
                    withPaddingY
                    withDivider
                    leftComponent={
                      <CreditCardIcon
                        fill={IconTheme.Primary}
                        className="w-5 h-5"
                      />
                    }
                    centerItems
                  />
                }/>
              )}
              {!!lastComment && (
                <ListItem
                  title={
                    lastComment.clientComment
                      ? intl.formatMessage({
                        id: 'my_reservations_modal_client_comment',
                      })
                      : intl.formatMessage(
                        {
                          id: 'my_reservations_modal_company_comment',
                        },
                        {companyTitle: reservation.company.title}
                      )
                  }
                  subTitle={
                    lastComment.clientComment || lastComment.companyComment
                  }
                  withDivider
                  withPaddingY
                  leftComponent={
                    <CommentEmptyIcon
                      fill={IconTheme.Primary}
                      className={classNames(
                        'w-4 h-4 mt-1',
                        lastComment.clientComment &&
                        'transform -scale-x-100 scale-y-100'
                      )}
                    />
                  }
                  withSubtitleEllipsis={false}
                  titleClassName="text-xs sm:text-sm text-accent"
                  subtitleClassName="text-sm sm:text-base text-primary"
                />
              )}
            </div>
            <div className="py-4">{footer}</div>
            {isModalOpen && (
              <Modal
                closeModal={closeModal}
                title={intl.formatMessage({ id: 'my_reservations_modal_cancel_cta' })}
                body={
                  <p className="mb-4 text-sm">
                    <FormattedMessage id="cancel_reservation_confirmation_message" />
                  </p>
                }
                footer={
                  <>
                    <Button
                      type="button"
                      size="sm"
                      className="mr-3"
                      theme="secondary"
                      onClick={closeModal}
                    >
                      <FormattedMessage id="button_keep" />
                    </Button>
                    <Button
                      type="button"
                      size="sm"
                      onClick={closeAndCancel}
                      isLoading={isLoading}
                      disabled={isLoading}
                    >
                      <FormattedMessage id="button_cancel" />
                    </Button>
                  </>
                }
                withMinHeight={false}
              />
            )}
          </>
        )}
      </main>
    </div>
  );
}

export const CancelReservationRoutes: React.FC = () => {
  const intl = useIntl();
  return (
    <Routes>
      <Route path="/:reservationId/cancel/:sign" element={<CancelReservationPage intl={intl}/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  );
};
