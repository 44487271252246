import {
  CustomTheme,
  setTheme,
  storage,
} from '@bookla-app/bookla-react-components';
import { logger } from 'utils/logger';
import { DefaultHomepageTab, getUrlParams } from 'utils/url';
import { isInIframe } from './isInIframe';
import { STORAGE_KEYS } from './storage';

interface WidgetOptionsContext {
  companyId: string;
  serviceId?: string;
  serviceDefaultSelectedDate?: Date;
  defaultHomepageTab?: DefaultHomepageTab;
  productId?: string;
  visibleServiceGroupIds?: string[];
  visibleProductIds?: string[];
  hideServicePrices?: boolean;
  cartEnabled?: boolean;
  commentToAppend?: string;
  promoCode?: string;
  theme?: Partial<CustomTheme>;
}

// const testTheme: Partial<Record<keyof CustomTheme, string>> = {
//   accent: '#EEEF4F',
//   accentLight: '#EEEF4F4D',
//   onAccent: '#000000',
//   background: '#000000',
//   surface: '#d0d0cf30',
//   divider: '#323232',
//   textPrimary: '#ffffff',
//   textSecondary: '#a0a0a0',
// };

function initWidgetOptions() {
  // Parses initial url which has widget options
  const {
    companyId = '',
    theme,
    serviceId,
    serviceDefaultSelectedDate,
    defaultHomepageTab,
    productId,
    visibleServiceGroupIds,
    visibleProductIds,
    hideServicePrices,
    cartEnabled,
    commentToAppend,
    promoCode,
    paymentToken,
  } = getUrlParams();

  const widgetOptions: WidgetOptionsContext = {
    companyId,
    theme,
    serviceId,
    serviceDefaultSelectedDate,
    defaultHomepageTab,
    productId,
    visibleServiceGroupIds,
    visibleProductIds,
    hideServicePrices,
    commentToAppend,
    promoCode,
    cartEnabled,
  };

  if (!isInIframe()) {
    const url = new URL(window.location.href);
    const STORED_WIDGET_OPTIONS_KEY = `${STORAGE_KEYS.widgetOptions}_${companyId}`;
    const storedWidgetOptions = storage.getLocalStorage(
      STORED_WIDGET_OPTIONS_KEY
    );

    // should not override widget options on redirect from payment
    if (url.search && !paymentToken) {
      storage.setLocalStorage(
        STORED_WIDGET_OPTIONS_KEY,
        JSON.stringify(widgetOptions)
      );
    } else if (storedWidgetOptions) {
      try {
        return JSON.parse(storedWidgetOptions) as WidgetOptionsContext;
      } catch (error) {
        logger.error(error);
      }
    }
  }

  return widgetOptions;
}

export const widgetOptions = initWidgetOptions();

// Sets theme in css variables
if (widgetOptions.theme) {
  setTheme(widgetOptions.theme, { appName: 'Bookla widget'/*, logger*/ });
}

export const getServiceDefaultSelectedDate = () => {
  const urlParams = getUrlParams();
  if (
    !widgetOptions.serviceId ||
    !widgetOptions.serviceDefaultSelectedDate ||
    urlParams.serviceId !== widgetOptions.serviceId
  ) {
    return undefined;
  }
  return { selectedDate: widgetOptions.serviceDefaultSelectedDate };
};

export const shouldHideServiceBackToHomepageButton = () => {
  const urlParams = getUrlParams();
  return (
    isInIframe() &&
    !!urlParams.serviceId &&
    urlParams.serviceId === widgetOptions.serviceId
  );
};
export const shouldHideProductBackToHomepageButton = () => {
  const urlParams = getUrlParams();
  return (
    isInIframe() &&
    !!urlParams.productId &&
    urlParams.productId === widgetOptions.productId
  );
};
