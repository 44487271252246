import { Calendar } from './calendar';
import { Image } from './common';
import { Company, PaymentProvider } from './company';
import { ProductType } from './product';
import { ScheduleType } from './schedule';
import { ServiceTicket, ServiceTicketGroup } from './ticket';

export interface PaymentOptions {
  card: boolean;
  cash: boolean;
  inApp: boolean;
}

export interface BaseService {
  currency: string;
  duration: number;
  id: string;
  originalPrice: number;
  price: number;
  serviceGroupId: string | null;
  title: string;
  type: ScheduleType;
  locationType: 'address' | 'online' | 'client';
}

export interface Service extends BaseService {
  description: string;
  image: Image;
  calendarPlaceholder?: string;
  calendarSelectionEnabled: boolean;
  pricedPerStep: boolean;
  maxSpotsPerUser: number;
  minSpotsPerUser: number;
  singleTicket: boolean;
  ticketTitle?: string;
  step?: number;
  requiresProductCode: boolean;
  explicitFeeEnabled: boolean;
  customTermsId?: string;
  paymentProviders?: PaymentProvider[];
  guestCheckoutEnabled?: boolean;
  customFormId?: string;
}

export interface ServiceResponse {
  calendars?: Calendar[] | null;
  company?: Company;
  service?: Service;
  tickets?: ServiceTicket[];
  ticketGroups?: ServiceTicketGroup[];
  paymentProviders?: PaymentProvider[];
}

export enum DiscountType {
  Percent = 'percent',
  Amount = 'amount',
  DeductibleAmount = 'deductibleAmount',
}

export interface PromoCode {
  code: string;
  currency: string;
  discount: number;
  discountType: DiscountType;
  id: string;
  productType: ProductType;
}

export interface ParsedPromoCode extends PromoCode {
  discountText: string;
}
