import { IntlShape } from 'react-intl';
import { PaymentOptionsType } from 'types/company';

export const getPaymentOptionsTranslation = (
  type: PaymentOptionsType | undefined,
  intl: IntlShape
) => {
  switch (type) {
    case PaymentOptionsType.CashCard:
      return intl.formatMessage({ id: 'service_page_payment_cash_card' });
    case PaymentOptionsType.Cash:
      return intl.formatMessage({ id: 'service_page_payment_cash' });
    case PaymentOptionsType.Card:
      return intl.formatMessage({ id: 'service_page_payment_card' });
    case PaymentOptionsType.InApp:
      return intl.formatMessage({ id: 'service_page_payment_inapp' });
    case PaymentOptionsType.Bank:
      return intl.formatMessage({ id: 'service_page_payment_bank' });
    default:
      return '';
  }
};

export const getPayedOptionsTranslation = (
  type: PaymentOptionsType,
  intl: IntlShape
) => {
  switch (type) {
    case PaymentOptionsType.CashCard:
      return intl.formatMessage({ id: 'summary_page_payment_type_card_cash' });
    case PaymentOptionsType.Cash:
      return intl.formatMessage({ id: 'summary_page_payment_type_cash' });
    case PaymentOptionsType.Card:
      return intl.formatMessage({ id: 'summary_page_payment_type_card' });
    case PaymentOptionsType.InApp:
      return intl.formatMessage({ id: 'summary_page_payment_type_in_app' });
    case PaymentOptionsType.Bank:
      return intl.formatMessage({ id: 'summary_page_payment_type_bank' });
    default:
      return '';
  }
};
