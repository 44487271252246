import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormattedCurrency,
  GiftIcon,
  IconTheme,
  ListItem,
  Thumbnail,
} from '@bookla-app/bookla-react-components';
import { getPath, ROUTES } from '../../../utils/routes';
import { useIntl } from 'react-intl';
import { GiftCard } from 'types/giftCard';

export const GiftCardItem: React.FC<GiftCard> = ({
  title,
  price,
  currency,
  id,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const goToDetailsPage = () => {
    navigate(getPath(ROUTES.GIFT_CARD, { giftCardId: id }));
  };

  return (
    <ListItem
      onClick={goToDetailsPage}
      leftComponent={
        <Thumbnail
          type="icon"
          bgColor="bg-gift"
          iconColor={IconTheme.Gift}
          icon={GiftIcon}
        />
      }
      title={title}
      rightComponent={
        <div className="flex flex-col text-right justify-between">
          <span className="font-medium text-sm sm:text-base">
            <FormattedCurrency value={price} currency={currency} intl={intl} />
          </span>
        </div>
      }
      withDivider={true}
      titleClassName="text-primary text-sm sm:text-base font-medium"
    />
  );
};
