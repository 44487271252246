import { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useAppState } from 'contexts/AppStateProvider';
import { DEFAULT_TRANSITION_DURATION_MS } from 'consts';
import { Toast } from '../toast';

const TOAST_DURATION_MS = 5000;

export const ToastContainer: React.FC = () => {
  const [startAnimation, setStartAnimation] = useState(false);
  const { toastMessages, removeToastMessage } = useAppState();
  const toast = toastMessages[0];
  const canClose = !toast?.reloadPage;
  const canAutoClose = canClose && !!toast?.autoClose;

  const removeToast = useCallback(() => {
    if (toast) {
      removeToastMessage(toast.id);
      setStartAnimation(false);
    }
  }, [removeToastMessage, toast]);

  useEffect(() => {
    let removeTimeoutId = 0;
    let animateTimeoutId = 0;
    if (canAutoClose) {
      animateTimeoutId = window.setTimeout(
        () => setStartAnimation(true),
        TOAST_DURATION_MS - DEFAULT_TRANSITION_DURATION_MS
      );
      removeTimeoutId = window.setTimeout(removeToast, TOAST_DURATION_MS);
    }

    return () => {
      window.clearTimeout(removeTimeoutId);
      window.clearTimeout(animateTimeoutId);
    };
  }, [removeToast, canAutoClose]);

  return (
    <div
      className={classNames(
        'absolute inset-0 flex justify-center items-end pointer-events-none z-50',
        toast && !canClose && 'pointer-events-auto bg-gray bg-opacity-60'
      )}
    >
      <div
        className={classNames(
          'transition-transform transform pb-8 pointer-events-auto max-w-4/5',
          toast && !startAnimation ? 'translate-y-0' : 'translate-y-full'
        )}
      >
        {!!toast && (
          <Toast {...toast} canClose={canClose} close={removeToast} />
        )}
      </div>
    </div>
  );
};
