export const getNextDays = (date: Date, limit = 90) => {
  const days = [date];
  const changingDate = new Date(date);

  for (let i = 0; i < limit - 1; i++) {
    changingDate.setDate(changingDate.getDate() + 1);
    const newDate = new Date(changingDate);
    // needs to be done after date change, so it takes into account daylight savings time changes
    newDate.setUTCHours(0, 0, 0, 0);
    days.push(newDate);
  }

  return days;
};

export const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getUTCDate() === date2.getUTCDate() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCFullYear() === date2.getUTCFullYear()
  );
};

export const isFirstDayOfMonth = (date: Date) => {
  return date.getDate() === 1;
};

export const getEndDate = (
  currentDate: Date,
  days: number,
  changeHours = true
) => {
  const endDate = new Date(currentDate);
  endDate.setDate(endDate.getDate() + days);
  if (changeHours) {
    endDate.setUTCHours(23, 59, 59);
  }

  return endDate;
};

export const getDaysFromRange = (start: string, end: string) => {
  return Math.floor(
    (Date.parse(end) - Date.parse(start)) / 1000 / 60 / 60 / 24
  );
};

/**
 * Api returns 2 millisecond integers, whereas js adds 3 ms integers
 */
export const toApiIsoDate = (date: Date) => {
  const isoDate = date.toISOString();
  return `${isoDate.slice(0, isoDate.length - 2)}Z`;
};

export const dateHasStarted = (startDate: string) => {
  return Date.parse(startDate) - Date.now() <= 0;
};
