import { Button, ButtonTheme } from '@bookla-app/bookla-react-components';
import classNames from 'classnames';
import { CartItem } from 'components/molecules/cartItem';
import { Modal } from 'components/molecules/modal';
import { useCart } from 'contexts/CartProvider/CartProvider';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ROUTES, getPath } from 'utils/routes';

interface CartModalProps {
  closeModal: () => void;
}

export const CartModal: React.FC<CartModalProps> = ({ closeModal }) => {
  const intl = useIntl();
  const { cart, cartItems, deleteCartItem, formattedTimer, timerSeconds } =
    useCart();
  const navigate = useNavigate();

  return (
    <Modal
      title={intl.formatMessage(
        { id: 'cart_modal_title' },
        { count: cart.count }
      )}
      closeModal={closeModal}
      goBack={closeModal}
      body={
        <>
          {!!timerSeconds && (
            <p className="-mt-7 -ml-5-w-full text-xs font-medium text-secondary py-2 px-5 border-b border-divider">
              {intl.formatMessage({ id: 'cart_modal_expiry_banner' })}{' '}
              <span
                className={classNames(
                  timerSeconds <= 60 && 'text-status-error'
                )}
              >
                {formattedTimer}
              </span>
            </p>
          )}
          <div className="px-1">
            {!!cart.count &&
              cartItems.map((cartItem) => (
                <CartItem
                  data={cartItem}
                  key={cartItem.id}
                  deleteCartItem={deleteCartItem}
                />
              ))}
            {!cart.count && (
              <p className="text-center text-secondary text-sm">
                {intl.formatMessage({ id: 'cart_modal_empty' })}
              </p>
            )}
          </div>
        </>
      }
      footer={
        !!cart.count ? (
          <>
            <Button
              onClick={() => {
                closeModal();
                navigate(getPath(ROUTES.SERVICES));
              }}
              theme={ButtonTheme.secondary}
              className="mr-3 grow"
            >
              {intl.formatMessage({ id: 'cart_modal_cta_more' })}
            </Button>
            <Button
              onClick={() => {
                closeModal();
                navigate(getPath(ROUTES.CART));
              }}
              className="grow"
            >
              {intl.formatMessage({ id: 'cart_modal_cta_checkout' })}
            </Button>
          </>
        ) : undefined
      }
    />
  );
};
