import { ScheduleType } from 'types/schedule';
import { ParsedTicket, SelectedTickets } from 'types/ticket';
import { ParsedTimeslot } from 'types/timeslot';

export const parseTicketsWithSpots = (
  selectedTimeslot: ParsedTimeslot,
  selectedTickets: SelectedTickets
) => {
  if (
    selectedTimeslot.type !== ScheduleType.Slot ||
    !selectedTimeslot.tickets?.length
  ) {
    return;
  }
  return Object.entries(selectedTickets).reduce<ParsedTicket[]>(
    (res, [id, spots]) => {
      if (!spots) {
        return res;
      }
      const timeslotTicket = (selectedTimeslot.tickets || []).find(
        ({ serviceTicketId }) => serviceTicketId === id
      );
      if (timeslotTicket) {
        return [...res, { ...timeslotTicket, spots }];
      }
      return res;
    },
    []
  );
};
