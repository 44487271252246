import {
  AuthToken,
  UserAuth,
  RequestVerificationResponse,
  VerifyCodeFailResponse,
  VerifyCodeSuccessResponse,
  RegisterUserParams,
  GuestLoginParams,
} from '../types/user';
import apiAxios from './apiAxios';

const BASE_ROUTE = '/api/v2/auth';
export const REFRESH_TOKEN_API_URL = `${BASE_ROUTE}/refresh`;

const authApi = {
  requestVerification: async (
    phoneNumber: string,
    alwaysSend?: boolean
  ): Promise<RequestVerificationResponse> => {
    return apiAxios.post(`${BASE_ROUTE}/code/request`, {
      phoneNumber,
      alwaysSend,
    });
  },
  verifyCode: async (
    phoneNumber: string,
    verificationCode: string
  ): Promise<VerifyCodeSuccessResponse | VerifyCodeFailResponse> => {
    return apiAxios.post(`${BASE_ROUTE}/code/verify`, {
      phoneNumber,
      code: verificationCode,
    });
  },
  login: async (phoneNumber: string, password: string): Promise<UserAuth> => {
    return apiAxios.post(`${BASE_ROUTE}/login`, {
      phoneNumber,
      password,
    });
  },
  register: async (body: RegisterUserParams): Promise<UserAuth> => {
    return apiAxios.post(`${BASE_ROUTE}/register`, body);
  },
  refreshToken: async (refreshToken: string): Promise<AuthToken> => {
    return apiAxios.post(REFRESH_TOKEN_API_URL, { token: refreshToken });
  },
  createPassword: async (body: {
    code: string;
    password: string;
    phoneNumber: string;
  }): Promise<UserAuth> => {
    return apiAxios.post(`${BASE_ROUTE}/password`, body);
  },
  changePassword: async (body: {
    current: string;
    password: string;
  }): Promise<AuthToken> => {
    return apiAxios.put(`${BASE_ROUTE}/password`, body);
  },
  guestLogin: async (data: GuestLoginParams): Promise<UserAuth> => {
    return apiAxios.post(`${BASE_ROUTE}/guest/register`, data);
  },
};

export default authApi;
