import { storage, Button } from '@bookla-app/bookla-react-components';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { useLayoutState } from 'contexts/layoutProvider/LayoutProvider';
import { useLocale } from 'contexts/translationsProvider';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getPrivacyPolicyLink } from 'utils/externalLinks';
import { logger } from 'utils/logger';
import { STORAGE_KEYS } from 'utils/storage';
import { CookieBannerSettings } from './utils';

const defaultSettings: CookieBannerSettings = {
  isStatisticsSelected: false,
};

export const CookieBanner: React.FC = () => {
  const intl = useIntl();
  const { language } = useLocale();
  const { isCookieBannerVisible, setCookieBannerVisible } = useLayoutState();
  const [settings, setSettings] =
    useState<CookieBannerSettings>(defaultSettings);

  const updateSettings = useCallback(
    (options: Partial<CookieBannerSettings>) => {
      setSettings((previous) => ({ ...previous, ...options }));
    },
    []
  );

  const save = useCallback(
    (options: CookieBannerSettings) => {
      storage.setCookie(
        STORAGE_KEYS.cookieConsent,
        JSON.stringify(options),
        365
      );
      setSettings(options);
      setCookieBannerVisible(false);
    },
    [setCookieBannerVisible]
  );

  useEffect(() => {
    try {
      const cookie = storage.getCookie(STORAGE_KEYS.cookieConsent);
      const settings = cookie
        ? (JSON.parse(cookie) as CookieBannerSettings)
        : undefined;

      setSettings({ ...defaultSettings, ...settings });
      if (!settings) {
        setCookieBannerVisible(true);
      }
    } catch (error) {
      logger.error(error);
    }
  }, [setCookieBannerVisible]);

  useEffect(() => {
    if (isCookieBannerVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isCookieBannerVisible]);

  if (!isCookieBannerVisible) {
    return null;
  }

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-auto bg-black/30 z-50">
      <div className="fixed bottom-0 left-0 right-0 w-full bg-primary p-4 text-sm">
        <h4 className="mb-3 font-semibold text-base">
          {intl.formatMessage({ id: 'cookie_banner_title' })}
        </h4>
        <p className="mb-3">
          {intl.formatMessage(
            { id: 'cookie_banner_description' },
            {
              privacyPolicyLink: (
                <ExternalLink href={getPrivacyPolicyLink(language)}>
                  {intl.formatMessage({ id: 'text_privacy_policy' })}
                </ExternalLink>
              ),
            }
          )}
        </p>
        <ul>
          <li className="border border-divider p-4 rounded-t-lg">
            <label className="flex items-center mb-2">
              <input
                className="mr-2 w-5 h-5"
                type="checkbox"
                id="cookie-banner-checkbox-necessary"
                checked
                disabled
              />
              <span className="font-semibold">
                {intl.formatMessage({ id: 'cookie_banner_type_necessary' })}
              </span>
            </label>
            <p>
              {intl.formatMessage({
                id: 'cookie_banner_type_necessary_description',
              })}
            </p>
          </li>
          <li className="border-b border-x border-divider p-4 rounded-b-lg">
            <label className="flex items-center mb-2 cursor-pointer">
              <input
                className="mr-2 w-5 h-5 bg-primary"
                type="checkbox"
                id="cookie-banner-checkbox-statistics"
                checked={settings.isStatisticsSelected}
                onChange={() =>
                  updateSettings({
                    isStatisticsSelected: !settings.isStatisticsSelected,
                  })
                }
              />
              <span className="font-semibold">
                {intl.formatMessage({ id: 'cookie_banner_type_statistics' })}
              </span>
            </label>
            <p>
              {intl.formatMessage({
                id: 'cookie_banner_type_statistics_description',
              })}
            </p>
          </li>
        </ul>
        <div className="flex items-center mt-4 justify-end">
          <Button
            size="xs"
            theme="danger"
            className="mr-2"
            outline
            onClick={() => save({ isStatisticsSelected: false })}
          >
            {intl.formatMessage({ id: 'cookie_banner_button_reject_all' })}
          </Button>
          <Button
            size="xs"
            theme="primary"
            className="mr-2"
            outline
            onClick={() => save(settings)}
          >
            {intl.formatMessage({ id: 'cookie_banner_button_allow_selected' })}
          </Button>
          <Button
            size="xs"
            theme="primary"
            onClick={() =>
              save({
                isStatisticsSelected: true,
              })
            }
          >
            {intl.formatMessage({ id: 'cookie_banner_button_allow_all' })}
          </Button>
        </div>
      </div>
    </div>
  );
};
