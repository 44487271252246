import {
  Reservation,
  ReservationDetails,
  ReservationListItem,
  ReservationParams,
  ReservationReviewInfo,
  Review,
} from 'types/reservation';
import { getIframeParentHost } from 'utils/url';
import apiAxios from './apiAxios';

const BASE_ROUTE = '/api/reservations';
const BASE_ROUTE_V3 = '/api/v3/reservations';

export const reservationApi = {
  getReservations: async (): Promise<ReservationListItem[]> =>
    await apiAxios.get(BASE_ROUTE),
  createReservation: async (data: ReservationParams): Promise<Reservation> => {
    return apiAxios.post(`${BASE_ROUTE_V3}/request`, data, {
      headers: { Source: getIframeParentHost() },
    });
  },
  getReservationDetails: async (
    reservationId: string
  ): Promise<ReservationDetails> =>
    apiAxios.get(`${BASE_ROUTE}/${reservationId}`),
  cancelReservation: async (
    reservationId: string,
    body: { comment: string; deleteFuture: boolean }
  ): Promise<void> =>
    apiAxios.post(`${BASE_ROUTE}/${reservationId}/cancel`, body),
  moveReservation: async (
    reservationId: string,
    body: {
      calendarId: string;
      slotId?: string;
      startDate: string;
    }
  ): Promise<Reservation> =>
    apiAxios.put(`${BASE_ROUTE}/${reservationId}/move`, body),
  addReview: (data: {
    imageIds?: string[];
    rating: number;
    reservationId: string;
    text?: string;
  }): Promise<Review> => apiAxios.post(`/api/reviews`, data),
  downloadTicket: async (reservationId: string): Promise<Blob> =>
    apiAxios.get(`${BASE_ROUTE}/${reservationId}/tickets`, {
      responseType: 'blob',
    }),
  getReviewInfo: async (
    reservationId: string,
    sign: string
  ): Promise<ReservationReviewInfo> =>
    apiAxios.get(`/api/feed/reservation/${reservationId}/review/${sign}`),
  addReservationReview: (
    reservationId: string,
    sign: string,
    data: {
      rating: number;
      text?: string;
    }
  ): Promise<Review> =>
    apiAxios.post(
      `/api/feed/reservation/${reservationId}/review/${sign}`,
      data
    ),
  getReservationInfoToCancel: async (
    reservationId: string,
    sign: string
  ): Promise<ReservationDetails> =>
    apiAxios.get(`/api/feed/reservation/${reservationId}/cancel/${sign}`),
  cancelReservationWithSign: async (
    reservationId: string,
    sign: string
  ): Promise<void> =>
    apiAxios.post(`/api/feed/reservation/${reservationId}/cancel/${sign}`),
};
