import { Category } from '@bookla-app/bookla-react-components';
import { Calendar } from './calendar';
import { Image } from './common';
import { Company } from './company';
import { ScheduleType } from './schedule';
import { PromoCode, Service } from './service';
import { ReservationTicket } from './ticket';
import { CustomFormWithValues } from './customForm';

export enum ReservationStatus {
  AwaitingPayment = 'awaitingPayment',
  Pending = 'pending',
  PendingPayment = 'pendingPayment',
  Confirmed = 'confirmed',
  Paid = 'paid',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  FailedPayment = 'failedPayment',
  Finished = 'finished',
  Missed = 'missed',
  Reviewed = 'reviewed',
  Requested = 'requested',
}

interface BaseReservation {
  deepLink: string;
  endDate: string;
  id: string;
  startDate: string;
  status: ReservationStatus;
  timeZone: string;
  originalPrice: number;
  price: number;
}

export interface ReservationListItem extends BaseReservation {
  categoryIcon: Category;
  companyTitle: string;
  serviceTitle: string;
  tickets?: {
    currency: string;
    id: string;
    price: number;
    serviceTicketId: string;
    slotTicketId: string;
    spots: number;
    title: string;
  }[];
  currency: string;
  createdAt: string;
}

export interface Reservation extends BaseReservation {
  calendarId: string;
  clientId: string;
  clientInitiated: boolean;
  companyId: string;
  createdAt: string;
  isFirstVisit: boolean;
  reason: string;
  serviceId: string;
  slotID: string;
  spotsReserved: number;
  templateId: string;
  type: ScheduleType;
}

interface ReservationService
  extends Omit<Service, 'originalPrice' | 'paymentProviders'> {
  afterReservationDelay: number;
  bookingEnabled: boolean;
  category: {};
  companyId: string;
  companyTitle: string;
  genderPreference: string;
  groupSlotService: boolean;
  hasCustomFields: boolean;
  maxReservationsPerUserPerDay: number;
  rating: number;
  scheduleColor: number;
  spotsTotal: number;
}

export interface BaseReview {
  rating: number;
  images?: Image[];
  text: string;
  timestamp: string;
}

export enum ReviewStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface Review extends BaseReview {
  id: string;
  rejectionReason?: string;
  status: ReviewStatus;
}

export interface ReservationReviewInfo {
  reservationId: string;
  companyName: string;
  serviceName: string;
  isReviewSubmitted: boolean;
  rating: number;
  text: string;
}

export interface ReservationDetails extends BaseReservation {
  calendar: Pick<Calendar, 'id' | 'title' | 'thumbnail' | 'type'>;
  clientInitiated: boolean;
  company: Company;
  currency: string;
  history: {
    clientComment?: string;
    companyComment?: string;
    from?: string;
    to: string;
    timestamp: string;
  }[];
  isBooklaUser: boolean;
  isExplicitFee: boolean;
  isSaved: boolean;
  review?: Review;
  service: ReservationService;
  templateId: string;
  type: ScheduleType;
  promoCode?: PromoCode;
  tickets?: ReservationTicket[];
  spots?: {
    total: number;
    taken: number;
    reserved: number;
  };
  ticketsBundleId?: string;
  payment?: {
    adjustedFeeAmount: number;
    cancellationFee: number;
    chargeError: boolean;
    chargedAt: string;
    currency: string;
    feeAmount: number;
    paymentId: string;
    payoutAmount: number;
    payoutAt: string | null;
    refundAmount: number;
    refundError: boolean;
    refundedAt: string | null;
    remainingRefundableAmount: number;
    totalAmount: number;
    transferError: boolean;
  };
}

export interface ReservationParams {
  calendarId: string;
  comment?: string;
  duration: number | null;
  promoCodeId?: string;
  serviceId: string;
  slotId?: string;
  spots?: number;
  startDate: string;
  tickets?: {
    slotTicketId: string;
    spots: number;
  }[];
  type: string;
  isBankTransfer: boolean;
  checkoutAsLegalEntity: boolean;
  checkoutAsLegalEntityComment?: string;
  customForm?: CustomFormWithValues;
}
