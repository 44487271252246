import { useAuth } from 'contexts/authProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectBack = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(-1);
  }, [navigate]);

  return null;
};

interface AuthenticatedPathProps {
  loggedInComponent: React.FC;
  loggedOutComponent: React.FC;
}

export const AuthenticatedPath: React.FC<AuthenticatedPathProps> = ({
  loggedInComponent: LoggedInComponent,
  loggedOutComponent: LoggedOutComponent,
}) => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <LoggedInComponent /> : <LoggedOutComponent />;
};
