import React, { HTMLAttributeAnchorTarget } from 'react';
import classNames from 'classnames';
import {
  ButtonBaseStyles,
  ButtonSize,
  ButtonSizeStyles,
  ButtonThemeStyles,
} from '@bookla-app/bookla-react-components';

export interface ExternalLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  href: string;
  children: React.ReactNode;
  className?: string;
  type?: 'link' | 'button' | 'none';
  target?: HTMLAttributeAnchorTarget;
  size?: keyof typeof ButtonSizeStyles;
  disabled?: boolean;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  children,
  className,
  type = 'link',
  target = '_blank',
  size = ButtonSize.md,
  disabled = false,
  ...props
}) => {
  return (
    <a
      {...props}
      className={classNames(
        className,
        type === 'link' && 'text-accent font-medium hover:underline',
        type === 'button' &&
          `${ButtonBaseStyles} ${ButtonSizeStyles[size]} ${ButtonThemeStyles.primary}`,
        disabled && 'pointer-events-none opacity-50'
      )}
      target={target}
      rel="noopener noreferrer"
      aria-disabled={disabled}
    >
      {children}
    </a>
  );
};
