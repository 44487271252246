import classNames from 'classnames';
import { CartIcon } from 'components/atoms/icons/CartIcon';
import { useCart } from 'contexts/CartProvider/CartProvider';
import { widgetOptions } from 'utils/widgetOptions';

interface CartButtonProps {}

export const CartButton: React.FC<CartButtonProps> = () => {
  const { cart, formattedTimer, timerSeconds, showCart } = useCart();

  if (!cart.count || !widgetOptions.cartEnabled) {
    return null;
  }

  return (
    <button
      className="flex justify-center items-center gap-1"
      onClick={showCart}
      type="button"
    >
      <CartIcon count={cart.count} />
      <span
        className={classNames(
          'w-14 h-5 px-1.5 py-0.5 rounded-2xl border border-divider justify-center items-center inline-flex text-center text-sm font-medium',
          timerSeconds <= 60 && 'text-status-error'
        )}
      >
        {formattedTimer}
      </span>
    </button>
  );
};
