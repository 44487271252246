export interface Image {
  height: number;
  id: string;
  thumbnail: string;
  url: string;
  width: number;
}

export interface FormattedMessageProps {
  id: string;
  values?: Record<string, React.ReactNode>;
}

export enum CartItemType {
  Service = 'service',
  Subscription = 'subscription',
  GiftCard = 'giftCard',
  Cart = 'cart',
}

export enum ItemCategories {
  Services = 'services',
  Products = 'products',
  GiftCards = 'giftCards',
}
