import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ExternalLink } from 'components/atoms/ExternalLink';
import {
  LogoIcon,
  SupportedLanguages,
} from '@bookla-app/bookla-react-components';
import { getBooklaLink } from 'utils/externalLinks';

interface LogoLinkProps {
  language: SupportedLanguages;
  className?: string;
}

export const LogoLink: React.FC<LogoLinkProps> = ({ className, language }) => {
  return (
    <ExternalLink
      href={getBooklaLink(language)}
      className={classNames(className, 'flex flex-row items-center')}
      type="none"
    >
      <>
        <small className="text-xxs mr-1">
          <FormattedMessage id="header_provided_by" />{' '}
          <strong className="font-semibold">
            <FormattedMessage id="header_bookla" />
          </strong>
        </small>
        <LogoIcon />
      </>
    </ExternalLink>
  );
};
