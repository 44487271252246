import { createContext, useContext, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { getUrlParams } from 'utils/url';
import { initialContextFunction } from 'utils/initialContextFunction';
import {
  DEFAULT_LOCALE,
  detectLocale,
  getLanguage,
  SUPPORTED_LOCALES,
  SupportedLanguages,
  SupportedLocales,
} from '@bookla-app/bookla-react-components';
import { Translations } from 'translations';

interface LocaleContext {
  locale: SupportedLocales;
  setLocale: (locale: SupportedLocales) => void;
  language: SupportedLanguages;
  timeZone?: string;
  setTimeZone: (timeZone?: string) => void;
}

const LOCALE = detectLocale();

const localeContext = createContext<LocaleContext>({
  locale: LOCALE,
  language: getLanguage(LOCALE),
  setTimeZone: initialContextFunction,
  setLocale: initialContextFunction,
});

export const useLocale = () => useContext(localeContext);

interface TranslationsProviderProps {
  messages: Translations;
  children: React.ReactNode;
}

export const TranslationsProvider: React.FC<TranslationsProviderProps> = ({
  children,
  messages,
}) => {
  const [locale, setLocale] = useState(
    SUPPORTED_LOCALES.find((loc) => loc.includes(getUrlParams().lang)) || LOCALE
  );
  const language = getLanguage(locale);
  const [timeZone, setTimeZone] = useState<string>();

  const context = useMemo(
    () => ({
      locale,
      language,
      timeZone,
      setTimeZone,
      setLocale: (newLocale: SupportedLocales) => {
        window.history.replaceState(
          {},
          '',
          window.location.href.replace(
            `/${getLanguage(locale)}`,
            `/${getLanguage(newLocale)}`
          )
        );
        setLocale(newLocale);
      },
    }),
    [locale, language, timeZone]
  );

  return (
    <localeContext.Provider value={context}>
      <IntlProvider
        messages={messages[language]}
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        timeZone={timeZone}
      >
        {children}
      </IntlProvider>
    </localeContext.Provider>
  );
};
