import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NotFoundPage } from 'components/pages/notFoundPage';

const CheckInPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "check-in-page" */ '../components/pages/checkInPage'
    )
);

export const CheckInRoutes: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITEKEY}`;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Routes>
      <Route path="/venues/:venueId" element={<CheckInPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
