import {
  CartListResponse,
  CartItemResponse,
  CartCountResponse,
} from 'types/cart';
import apiAxios from './apiAxios';
import { ReservationParams } from 'types/reservation';

const BASE_ROUTE = '/api/cart';

export const cartApi = {
  getCartItems: (): Promise<CartListResponse> => apiAxios.get(BASE_ROUTE),
  addToCart: (body: ReservationParams): Promise<void> =>
    apiAxios.post(BASE_ROUTE, body),
  clearWholeCart: (): Promise<void> => apiAxios.delete(BASE_ROUTE),
  getCartItem: (cartItemId: string): Promise<CartItemResponse> =>
    apiAxios.get(`${BASE_ROUTE}/${cartItemId}`),
  getCartItemsCount: (): Promise<CartCountResponse> =>
    apiAxios.get(`${BASE_ROUTE}/count`),
  deleteCartItem: (cartItemId: string): Promise<void> =>
    apiAxios.delete(`${BASE_ROUTE}/${cartItemId}`),
};
