import { PaymentMethod } from '@stripe/stripe-js';

export interface PaymentBreakdown {
  basePrice: number;
  currency: string;
  fee: number;
  feeVat: number;
  totalPrice: number;
}

export enum PaymentType {
  Card = 'card',
  Bank = 'bank',
  ApplePay = 'applePay',
  GooglePay = 'googlePay',
  OnSpot = 'onSpot',
}

export interface CardPaymentMethod {
  type: PaymentType.Card;
  paymentMethod: PaymentMethod;
  saveCard: boolean;
}

export interface BankPaymentMethod {
  type: PaymentType.Bank;
}

export type SelectedPaymentMethod = CardPaymentMethod | BankPaymentMethod;
