import { User } from 'types/user';
import apiAxios from './apiAxios';

const BASE_ROUTE = '/api/user/me';

export const userApi = {
  updateEmail: async (email: string): Promise<User> =>
    apiAxios.put(`${BASE_ROUTE}/email`, { email }),
  changeLanguage : async (): Promise<unknown> =>
    apiAxios.patch(`${BASE_ROUTE}/language`),
};
