import { IntlShape } from 'react-intl';
import { ParsedTimeslot } from 'types/timeslot';

export const getBookingDateAndTime = (
  timeslot: Pick<
    ParsedTimeslot,
    'startTime' | 'startTimeFormatted' | 'endTimeFormatted'
  > | null,
  intl: IntlShape
) => {
  if (!timeslot) {
    return '';
  }

  const date = intl.formatDate(timeslot.startTime, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return `${date}, ${timeslot.startTimeFormatted} - ${timeslot.endTimeFormatted}`;
};
