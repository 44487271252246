import { ScheduleType } from 'types/schedule';
import { Service } from 'types/service';

export const canDisplayParticipants = (
  service: Pick<Service, 'type' | 'maxSpotsPerUser'> | undefined,
  tickets: unknown[]
) =>
  !!service &&
  service.type === ScheduleType.Slot &&
  service.maxSpotsPerUser !== 1 &&
  !tickets.length;

export const canDisplayTickets = (
  service: Pick<Service, 'type'> | undefined,
  tickets: unknown[]
) => !!service && service.type === ScheduleType.Slot && tickets.length > 0;
