import classNames from 'classnames';
import { Link } from 'react-router-dom';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from 'contexts/authProvider';
import {
  IconButton,
  ArrowLeftIcon,
  Thumbnail,
} from '@bookla-app/bookla-react-components';
import { useLocale } from 'contexts/translationsProvider';
import { LogoLink } from '../logoLink';
import { LanguageSelect } from 'components/atoms/languageSelect';
import { getPath, ROUTES } from 'utils/routes';
import { CartButton } from '../cartButton';
import { widgetOptions } from 'utils/widgetOptions';

export interface HeaderProps {
  title?: string | JSX.Element;
  goBack?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ title, goBack }) => {
  const intl = useIntl();
  const { user, isLoggedIn } = useAuth();
  const { language } = useLocale();
  const titleIsElement = !!title && typeof title !== 'string';

  return (
    <header
      className={classNames(
        'flex flex-col flex-grow-0 flex-shrink-0 my-3.5 mx-5',
        widgetOptions.cartEnabled
          ? 'md:flex-row md:place-content-between md:my-7'
          : 'sm:flex-row sm:place-content-between sm:my-7',
        'md:mx-10'
      )}
    >
      <div
        className={classNames(
          'flex items-center',
          'order-2',
          widgetOptions.cartEnabled ? 'md:order-1' : 'sm:order-1'
        )}
      >
        {goBack && (
          <div className="mr-5 flex-shrink-0">
            <IconButton
              onClick={goBack}
              ariaLabel={intl.formatMessage({
                id: 'header_back_to_previous_page_aria_label',
              })}
              icon={ArrowLeftIcon}
              type="button"
            />
          </div>
        )}
        {titleIsElement ? (
          title
        ) : (
          <h1 className={classNames('text-xl sm:text-2xl font-medium')}>
            {title}
          </h1>
        )}
      </div>
      <div
        className={classNames(
          'flex flex-nowrap place-items-center',
          'order-1 self-end',
          widgetOptions.cartEnabled
            ? 'md:order-2 md:self-auto'
            : 'sm:order-2 sm:self-auto'
        )}
      >
        <CartButton />
        <LanguageSelect className="mx-4" />
        {isLoggedIn && !user?.isGuest ? (
          <Link
            to={getPath(ROUTES.PROFILE)}
            className="flex place-items-center rounded-lg -mx-2 px-2"
          >
            <span
              className={classNames(
                'text-sm mr-4 hidden',
                widgetOptions.cartEnabled ? 'lg:block' : 'sm:block'
              )}
            >
              {user?.firstName}
              {user?.lastName ? ` ${user.lastName}` : ''}
            </span>
            <Thumbnail
              type="image"
              url={user?.image?.thumbnail}
              size="sm"
              shape="circle"
            />
          </Link>
        ) : (
          <LogoLink language={language} />
        )}
      </div>
    </header>
  );
};
