import {
  CompanyResponse,
  CompanyService,
  CompanyServiceGroup,
} from 'types/company';

export interface SortedService {
  services: CompanyService[];
  groupTitle?: string;
}

export const parseServicesIntoGroups = (
  data: Pick<CompanyResponse, 'serviceGroups' | 'services'>,
  otherServicesTabTitle: string,
  visibleServiceGroupIds?: string[]
): {
  serviceGroups: CompanyServiceGroup[];
  groupedServices: SortedService[];
} => {
  const unsortedServices = (data?.services || []).sort((a, b) =>
    a.title.localeCompare(b.title)
  );
  const serviceGroups = (data?.serviceGroups || [])
    .filter((serviceGroup) =>
      unsortedServices.some(
        (service) => service.serviceGroupId === serviceGroup.id
      )
    )
    .filter((serviceGroup) =>
      visibleServiceGroupIds
        ? visibleServiceGroupIds.includes(serviceGroup.id)
        : true
    )
    .sort((a, b) => b.priority - a.priority);

  if (!serviceGroups.length) {
    return {
      serviceGroups,
      groupedServices: [{ services: unsortedServices }],
    };
  }

  const sortedServices = serviceGroups.map(({ id, title }) => {
    const services = unsortedServices.filter(
      ({ serviceGroupId }) => serviceGroupId === id
    );
    return { services, groupTitle: title };
  });

  //if visibleServiceGroupIds is set, we don't need to show other services
  if(visibleServiceGroupIds) {
    return {
      serviceGroups,
      groupedServices: sortedServices,
    };
  }

  // for group-less services
  const otherServicesTab = {
    id: 'other-services',
    title: otherServicesTabTitle,
    priority: 9999,
  };
  const grouplessServices = unsortedServices
    .filter(({ serviceGroupId }) => !serviceGroupId)
    .map((s) => ({ ...s, serviceGroupId: otherServicesTab.id }));

  if (grouplessServices.length) {
    return {
      serviceGroups: [...serviceGroups, otherServicesTab],
      groupedServices: [
        ...sortedServices,
        {
          services: grouplessServices,
          groupTitle: otherServicesTab.title,
        },
      ],
    };
  }

  return {
    serviceGroups,
    groupedServices: sortedServices,
  };
};
