import {
  ButtonTheme,
  DiscountIcon,
  GiftIcon,
  SubscriptionIcon,
  IconProps,
  IconTheme,
} from '@bookla-app/bookla-react-components';
import { IntlShape } from 'react-intl';
import { ProductType } from 'types/product';

export const productToIconMap: Record<ProductType, React.FC<IconProps>> = {
  [ProductType.GiftCard]: GiftIcon,
  [ProductType.Subscription]: SubscriptionIcon,
  [ProductType.Code]: DiscountIcon,
};

export const productToBgColorMap: Record<ProductType, string> = {
  [ProductType.GiftCard]: 'bg-gift',
  [ProductType.Subscription]: 'bg-subscription',
  [ProductType.Code]: 'bg-promo',
};

export const productToTextColorMap: Record<ProductType, string> = {
  [ProductType.GiftCard]: 'text-gift',
  [ProductType.Subscription]: 'text-subscription',
  [ProductType.Code]: 'text-promo',
};

export const productToIconColorMap: Record<ProductType, IconTheme> = {
  [ProductType.GiftCard]: IconTheme.Gift,
  [ProductType.Subscription]: IconTheme.Subscription,
  [ProductType.Code]: IconTheme.Promo,
};

export const productToButtonThemeMap: Record<ProductType, ButtonTheme> = {
  [ProductType.GiftCard]: ButtonTheme.gift,
  [ProductType.Subscription]: ButtonTheme.subscription,
  [ProductType.Code]: ButtonTheme.promo,
};

export const getPromoNameTranslation = (
  productType: ProductType | undefined,
  intl: IntlShape
) => {
  switch (productType) {
    case ProductType.GiftCard:
      return intl.formatMessage({ id: 'summary_page_code_type_gift_card' });
    case ProductType.Subscription:
      return intl.formatMessage({ id: 'summary_page_code_type_subscription' });
    case ProductType.Code:
    default:
      return intl.formatMessage({ id: 'summary_page_code_type_promo' });
  }
};
