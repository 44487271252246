import { Metric } from 'web-vitals';
import { SavedCheckInData } from 'types/checkIn';
import { Reservation } from 'types/reservation';
import { User } from 'types/user';
import { StripeError } from '@stripe/stripe-js';
import { PaymentType } from 'types/payments';
import { analyticsAdaptor } from './AnalyticsAdaptor';

export const ANALYTICS_EVENTS = {
  performance: 'performance',
  signUp: 'sign_up',
  payment: 'payment',
  booking: 'booking',
  pageServices: 'page_services',
  pageBooking: 'page_booking',
  pageCheckout: 'page_checkout',
  pageProfile: 'page_profile',
  pageCheckIn: 'page_check_in',
  pageDownloadApp: 'page_download_app',
  pageNotFound: 'page_not_found',
  pageError: 'page_error',
  paymentError: 'payment_error',
  paymentSaveError: 'payment_save_error',
  checkedIn: 'checked_in',
};

export const analytics = {
  performance: ({ id, name, value, delta }: Metric) => {
    analyticsAdaptor.track(ANALYTICS_EVENTS.performance, {
      id,
      name,
      value,
      delta,
    });
  },
  identifyUser: (user: User) => {
    analyticsAdaptor.identify(user.id);
  },
  signUp: () => {
    analyticsAdaptor.track(ANALYTICS_EVENTS.signUp);
  },
  logout: () => {
    analyticsAdaptor.reset();
  },
  pageLoaded: (
    page: Extract<
      keyof typeof ANALYTICS_EVENTS,
      | 'pageServices'
      | 'pageBooking'
      | 'pageCheckout'
      | 'pageProfile'
      | 'pageDownloadApp'
      | 'pageNotFound'
      | 'pageCheckIn'
      | 'pageError'
    >
  ) => {
    analyticsAdaptor.track(ANALYTICS_EVENTS[page]);
  },
  payment: ({ amount, currency }: { amount: number; currency: string }) => {
    analyticsAdaptor.track(ANALYTICS_EVENTS.payment, {
      amount,
      currency,
    });
  },
  paymentError: (code: string) => {
    analyticsAdaptor.track(ANALYTICS_EVENTS.paymentError, { code });
  },
  paymentSaveError: (
    data: Pick<StripeError, 'code' | 'type' | 'decline_code'>
  ) => {
    analyticsAdaptor.track(ANALYTICS_EVENTS.paymentSaveError, data);
  },
  booking: (
    {
      serviceId,
      companyId,
      price,
      spotsReserved,
    }: Pick<Reservation, 'serviceId' | 'companyId' | 'price' | 'spotsReserved'>,
    {
      currency,
      payment,
    }: {
      currency: string;
      payment: PaymentType;
    }
  ) => {
    analyticsAdaptor.track(ANALYTICS_EVENTS.booking, {
      companyId,
      serviceId,
      price,
      currency,
      spots: spotsReserved,
      payment,
    });
  },
  checkedIn: (data: SavedCheckInData) => {
    analyticsAdaptor.track(ANALYTICS_EVENTS.checkedIn, data);
  },
};
