import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { v4 as uuidv4 } from 'uuid';
import { Calendar } from 'types/calendar';
import { ScheduleType } from 'types/schedule';
import { Company, PaymentProvider } from 'types/company';
import { ParsedPromoCode, Service, ServiceResponse } from 'types/service';
import {
  SelectedTickets,
  ServiceTicket,
  ServiceTicketGroup,
} from 'types/ticket';
import { initialContextFunction } from 'utils/initialContextFunction';
import {
  BookingOptions,
  BookingOptionsState,
  getInitialBookingOptionsState,
  useBookingOptions,
} from 'utils/useBookingOptions';
import { ParsedTimeslot } from 'types/timeslot';
import { useIntl } from 'react-intl';
import {
  formatCurrency,
  addDiscount,
  convertFromCents,
} from '@bookla-app/bookla-react-components';
import { useLocale } from 'contexts/translationsProvider';
import { CardPaymentMethod, PaymentType } from 'types/payments';
import { getUrlParams, hasUrlConfigId } from 'utils/url';
import { ToastMessageProps } from 'components/molecules/toast';
import { Product } from 'types/product';
import { GiftCardDesign, GiftCardDetails } from 'types/giftCard';
import { canDisplayParticipants, canDisplayTickets } from './utils';
import { CustomFormWithValues } from 'types/customForm';
import { widgetConfigApi } from '../../api/widgetConfig';

interface GetTotalServicePriceParams {
  timeslot: ParsedTimeslot;
  isBankTransfer?: boolean;
  promoCode?: ParsedPromoCode;
  inCents?: boolean;
}

type GetTotalServiceFormattedPriceParams = Omit<
  GetTotalServicePriceParams,
  'inCents'
>;

interface GetTotalProductPriceParams {
  product: Product;
  inCents?: boolean;
}

type GetTotalProductFormattedPriceParams = Omit<
  GetTotalProductPriceParams,
  'inCents'
>;

interface GetTotalGiftCardPriceParams {
  giftCard: GiftCardDetails;
  inCents?: boolean;
}

type GetTotalGiftCardFormattedPriceParams = Omit<
  GetTotalGiftCardPriceParams,
  'inCents'
>;

export interface AppStateContext {
  bookingOptions: BookingOptions;
  product?: Product;
  service?: Service;
  company?: Company;
  calendars: Calendar[];
  tickets: ServiceTicket[];
  ticketGroups?: ServiceTicketGroup[];
  giftCard?: GiftCardDetails;
  setGiftCard: (giftCard?: GiftCardDetails) => void;
  giftCardDesign?: GiftCardDesign;
  setGiftCardDesign: (giftCardDesign?: GiftCardDesign) => void;
  giftCardMessage: string;
  setGiftCardMessage: (giftCardMessage: string) => void;
  setProduct: (product?: Product) => void;
  setCompanyDetails: (company: Company) => void;
  setServiceDetails: (res: ServiceResponse) => void;
  setBookingDetails: (
    service: Service,
    tickets?: ServiceTicket[],
    ticketGroups?: ServiceTicketGroup[]
  ) => void;
  cardPaymentMethods: CardPaymentMethod[];
  addCardPaymentMethod: (
    paymentMethod: Omit<CardPaymentMethod, 'type'>
  ) => void;
  removeCardPaymentMethod: (id: string) => void;
  setCardPaymentMethods: (paymentMethods: PaymentMethod[]) => void;
  calendarOfTimeslot: Calendar | undefined;
  shouldDisplayDuration: boolean;
  shouldDisplayParticipants: boolean;
  shouldDisplayTickets: boolean;
  getTotalServicePrice: (props: GetTotalServicePriceParams) => number;
  getTotalServiceFormattedPrice: (
    props: GetTotalServiceFormattedPriceParams
  ) => string;
  getTotalProductPrice: (props: GetTotalProductPriceParams) => number;
  getTotalProductFormattedPrice: (
    props: GetTotalProductFormattedPriceParams
  ) => string;
  getTotalGiftCardPrice: (props: GetTotalGiftCardPriceParams) => number;
  getTotalGiftCardFormattedPrice: (
    props: GetTotalGiftCardFormattedPriceParams
  ) => string;
  toastMessages: (ToastMessageProps | undefined)[];
  addToastMessage: (toast: Omit<ToastMessageProps, 'id'>) => void;
  removeToastMessage: (id: string) => void;
  resetAppState: () => void;
  selectedSpots: number;
  canPayServiceWithProvider: (paymentProvider: PaymentProvider) => boolean;
  canPayProductWithProvider: (paymentProvider: PaymentProvider) => boolean;
  canPayGiftCardWithProvider: (paymentProvider: PaymentProvider) => boolean;
  subscriptionCustomForm?: CustomFormWithValues;
  setSubscriptionCustomForm: (customForm?: CustomFormWithValues) => void;
  serviceCustomForm?: CustomFormWithValues;
  setServiceCustomForm: (customForm?: CustomFormWithValues) => void;
}

const appStateContext = createContext<AppStateContext>({
  bookingOptions: {
    ...getInitialBookingOptionsState(),
    selectedDate: new Date(),
    today: new Date(),
    setSelectedDate: initialContextFunction,
    setSelectedCalendar: initialContextFunction,
    setSelectedDuration: initialContextFunction,
    setSelectedParticipants: initialContextFunction,
    setSelectedTickets: initialContextFunction,
    setSelectedTimeslot: initialContextFunction,
    resetBookingState: initialContextFunction,
    resetSelectedDate: initialContextFunction,
    totalSelectedTickets: 0,
    hasFormChanges: false,
  },
  calendars: [],
  tickets: [],
  setGiftCard: initialContextFunction,
  setGiftCardDesign: initialContextFunction,
  setProduct: initialContextFunction,
  setCompanyDetails: initialContextFunction,
  setServiceDetails: initialContextFunction,
  setBookingDetails: initialContextFunction,
  cardPaymentMethods: [],
  addCardPaymentMethod: initialContextFunction,
  setCardPaymentMethods: initialContextFunction,
  removeCardPaymentMethod: initialContextFunction,
  calendarOfTimeslot: undefined,
  shouldDisplayDuration: false,
  shouldDisplayParticipants: false,
  shouldDisplayTickets: false,
  getTotalServicePrice: initialContextFunction,
  getTotalServiceFormattedPrice: initialContextFunction,
  getTotalProductPrice: initialContextFunction,
  getTotalProductFormattedPrice: initialContextFunction,
  getTotalGiftCardPrice: initialContextFunction,
  getTotalGiftCardFormattedPrice: initialContextFunction,
  toastMessages: [],
  addToastMessage: initialContextFunction,
  removeToastMessage: initialContextFunction,
  resetAppState: initialContextFunction,
  selectedSpots: 1,
  canPayServiceWithProvider: () => false,
  canPayProductWithProvider: () => false,
  canPayGiftCardWithProvider: () => false,
  giftCardMessage: '',
  setGiftCardMessage: initialContextFunction,
  setSubscriptionCustomForm: initialContextFunction,
  setServiceCustomForm: initialContextFunction,
});

export const useAppState = () => useContext(appStateContext);
export const useBookingOptionsState = () =>
  useContext(appStateContext).bookingOptions;
export const useFullDetailsState = () => {
  const { bookingOptions, company, service, product, ...fullDetailsState } =
    useContext(appStateContext);
  return {
    company: company as Company,
    service: service as Service,
    product: product as Product,
    ...fullDetailsState,
  };
};

export const AppStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const intl = useIntl();

  const [service, setService] = useState<Service>();
  const [company, setCompany] = useState<Company>();
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [tickets, setTickets] = useState<ServiceTicket[]>([]);
  const [ticketGroups, setTicketGroups] = useState<ServiceTicketGroup[]>([]);
  const [product, setProduct] = useState<Product>();
  const [giftCard, setGiftCard] = useState<GiftCardDetails>();
  const [giftCardDesign, setGiftCardDesign] = useState<GiftCardDesign>();
  const [giftCardMessage, setGiftCardMessage] = useState('');
  const [cardPaymentMethods, setCardPaymentMethods] = useState<
    CardPaymentMethod[]
  >([]);
  const [toastMessages, setToastMessages] = useState<ToastMessageProps[]>([]);
  const [subscriptionCustomForm, setSubscriptionCustomForm] =
    useState<CustomFormWithValues>();
  const [serviceCustomForm, setServiceCustomForm] =
    useState<CustomFormWithValues>();

  const bookingOptions = useBookingOptions();
  const {
    setSelectedDuration,
    setSelectedDate,
    setSelectedCalendar,
    setSelectedParticipants,
    setSelectedTickets,
    setSelectedTimeslot,
    selectedDuration,
    selectedCalendar,
    selectedTimeslot,
    selectedParticipants,
    resetBookingState,
    totalSelectedTickets,
  } = bookingOptions;
  const { setTimeZone } = useLocale();

  const resetAppState = useCallback(() => {
    resetBookingState();
    setService(undefined);
    setCalendars([]);
    setTickets([]);
    setTicketGroups([]);
    setCardPaymentMethods([]);
    setToastMessages([]);
    setProduct(undefined);
    setGiftCard(undefined);
    setGiftCardDesign(undefined);
    setGiftCardMessage('');
    setSubscriptionCustomForm(undefined);
    setServiceCustomForm(undefined);
  }, [resetBookingState]);

  const setCompanyDetails = useCallback(
    (company: Company) => {
      setTimeZone(company.timeZone);
      setCompany(company);
    },
    [setTimeZone]
  );

  const setServiceDetails = useCallback((res: ServiceResponse) => {
    // tickets before service, so when form state is set based on service, tickets are also set
    setTickets(
      res.tickets?.sort((a, b) => a.title.localeCompare(b.title)) || []
    );
    setTicketGroups(res.ticketGroups || []);
    setService(
      res.service
        ? { ...res.service, paymentProviders: res.paymentProviders }
        : undefined
    );
    setCalendars(res.calendars || []);
  }, []);

  const setBookingDetails = useCallback(
    (service: Service, tickets?: ServiceTicket[]) => {
      if (hasUrlConfigId()) {
        return;
      }
      if (service) {
        if (service.pricedPerStep) {
          setSelectedDuration(service.duration);
        }
        setSelectedParticipants(service.minSpotsPerUser || 1);
      }
      if (tickets?.length) {
        const initialSelectedTickets = tickets.reduce<SelectedTickets>(
          (res, ticket) => {
            res[ticket.id] = 0;
            return res;
          },
          {}
        );
        setSelectedTickets(initialSelectedTickets);
      }
    },
    [setSelectedDuration, setSelectedParticipants, setSelectedTickets]
  );

  const addCardPaymentMethod = useCallback(
    (paymentMethod: Omit<CardPaymentMethod, 'type'>) => {
      setCardPaymentMethods((prev) => [
        ...prev,
        { ...paymentMethod, type: PaymentType.Card },
      ]);
    },
    []
  );

  const setAndParseCardPaymentMethods = useCallback(
    (payments: PaymentMethod[]) => {
      setCardPaymentMethods(
        payments.map((paymentMethod) => ({
          type: PaymentType.Card,
          paymentMethod,
          saveCard: true,
        }))
      );
    },
    []
  );

  const removeCardPaymentMethod = useCallback((id: string) => {
    setCardPaymentMethods((prev) =>
      prev.filter((pm) => pm.paymentMethod.id !== id)
    );
  }, []);

  const shouldDisplayDuration = useMemo(
    () =>
      !!service &&
      service.type === ScheduleType.Time &&
      !!service.pricedPerStep &&
      !!service.duration &&
      !!selectedDuration,
    [selectedDuration, service]
  );

  const shouldDisplayParticipants = useMemo(
    () => canDisplayParticipants(service, tickets),
    [service, tickets]
  );

  const shouldDisplayTickets = useMemo(
    () => canDisplayTickets(service, tickets),
    [service, tickets]
  );

  const calendarOfTimeslot = useMemo(
    () =>
      selectedCalendar ||
      calendars.find(
        (calendar) => calendar.id === selectedTimeslot?.calendarId
      ),
    [selectedCalendar, selectedTimeslot?.calendarId, calendars]
  );

  const getTotalServicePrice = useCallback(
    ({
      timeslot,
      promoCode,
      inCents = false,
      isBankTransfer = true,
    }: GetTotalServicePriceParams) => {
      let value: number;
      if (timeslot.type === ScheduleType.Time) {
        value = timeslot.price;
      } else if (shouldDisplayParticipants) {
        value = timeslot.price * selectedParticipants;
      } else if (isBankTransfer && service?.explicitFeeEnabled) {
        value =
          timeslot.bankTransferPriceInBundle ??
          timeslot.priceInBundle ??
          timeslot.bankTransferPrice ??
          timeslot.price;
      } else {
        value = timeslot.priceInBundle ?? timeslot.price;
      }

      if (promoCode) {
        value = addDiscount(value, promoCode);
      }

      return Math.max(
        inCents ? value : convertFromCents(value, timeslot.currency),
        0
      );
    },
    [
      selectedParticipants,
      service?.explicitFeeEnabled,
      shouldDisplayParticipants,
    ]
  );

  const getTotalServiceFormattedPrice = useCallback(
    (props: GetTotalServiceFormattedPriceParams) => {
      return formatCurrency({
        currency: props.timeslot.currency,
        value: getTotalServicePrice({ ...props, inCents: true }),
        intl,
      });
    },
    [getTotalServicePrice, intl]
  );

  const getTotalProductPrice = useCallback(
    ({ product, inCents = false }: GetTotalProductPriceParams) => {
      return Math.max(
        inCents
          ? product.price
          : convertFromCents(product.price, product.currency),
        0
      );
    },
    []
  );

  const getTotalProductFormattedPrice = useCallback(
    ({ product }: GetTotalProductFormattedPriceParams) => {
      return formatCurrency({
        currency: product.currency,
        value: getTotalProductPrice({ product, inCents: true }),
        intl,
      });
    },
    [getTotalProductPrice, intl]
  );

  const getTotalGiftCardPrice = useCallback(
    ({ giftCard, inCents = false }: GetTotalGiftCardPriceParams) => {
      return Math.max(
        inCents
          ? giftCard.price
          : convertFromCents(giftCard.price, giftCard.currency),
        0
      );
    },
    []
  );

  const getTotalGiftCardFormattedPrice = useCallback(
    ({ giftCard }: GetTotalGiftCardFormattedPriceParams) => {
      return formatCurrency({
        currency: giftCard.currency,
        value: getTotalGiftCardPrice({ giftCard, inCents: true }),
        intl,
      });
    },
    [getTotalGiftCardPrice, intl]
  );

  const addToastMessage = useCallback(
    (toast: Omit<ToastMessageProps, 'id'>) => {
      setToastMessages((prev) => [
        { autoClose: true, ...toast, id: uuidv4() },
        ...prev,
      ]);
    },
    []
  );

  const removeToastMessage = useCallback((id: string) => {
    setToastMessages((prev) => prev.filter((toast) => toast.id !== id));
  }, []);

  const selectedSpots = useMemo(() => {
    if (shouldDisplayParticipants) {
      return selectedParticipants;
    }
    if (shouldDisplayTickets) {
      return totalSelectedTickets;
    }

    return 1;
  }, [
    selectedParticipants,
    shouldDisplayParticipants,
    shouldDisplayTickets,
    totalSelectedTickets,
  ]);

  const canPayServiceWithProvider = useCallback(
    (paymentProvider: PaymentProvider) => {
      const hasEnabledInService =
        !!service?.paymentProviders?.includes(paymentProvider);

      return (
        hasEnabledInService &&
        (calendarOfTimeslot ? !!calendarOfTimeslot?.stripeEnabled : true)
      );
    },
    [calendarOfTimeslot, service?.paymentProviders]
  );

  const canPayProductWithProvider = useCallback(
    (paymentProvider: PaymentProvider) => {
      return !!product?.paymentProviders?.includes(paymentProvider);
    },
    [product?.paymentProviders]
  );

  const canPayGiftCardWithProvider = useCallback(
    (paymentProvider: PaymentProvider) => {
      return !!giftCard?.paymentProviders?.includes(paymentProvider);
    },
    [giftCard?.paymentProviders]
  );

  const context: AppStateContext = useMemo(
    () => ({
      bookingOptions,
      service,
      company,
      tickets,
      ticketGroups,
      calendars,
      setServiceDetails,
      setCompanyDetails,
      setBookingDetails,
      cardPaymentMethods,
      addCardPaymentMethod,
      setCardPaymentMethods: setAndParseCardPaymentMethods,
      removeCardPaymentMethod,
      shouldDisplayDuration,
      shouldDisplayParticipants,
      calendarOfTimeslot,
      shouldDisplayTickets,
      getTotalServicePrice,
      getTotalServiceFormattedPrice,
      getTotalProductPrice,
      getTotalProductFormattedPrice,
      getTotalGiftCardPrice,
      getTotalGiftCardFormattedPrice,
      toastMessages,
      addToastMessage,
      removeToastMessage,
      resetAppState,
      selectedSpots,
      product,
      setProduct,
      canPayServiceWithProvider,
      canPayProductWithProvider,
      canPayGiftCardWithProvider,
      giftCard,
      setGiftCard,
      giftCardDesign,
      setGiftCardDesign,
      giftCardMessage,
      setGiftCardMessage,
      subscriptionCustomForm,
      setSubscriptionCustomForm,
      serviceCustomForm,
      setServiceCustomForm,
    }),
    [
      bookingOptions,
      service,
      company,
      tickets,
      ticketGroups,
      calendars,
      setServiceDetails,
      setCompanyDetails,
      setBookingDetails,
      cardPaymentMethods,
      addCardPaymentMethod,
      setAndParseCardPaymentMethods,
      removeCardPaymentMethod,
      shouldDisplayDuration,
      shouldDisplayParticipants,
      calendarOfTimeslot,
      shouldDisplayTickets,
      getTotalServicePrice,
      getTotalServiceFormattedPrice,
      getTotalProductPrice,
      getTotalProductFormattedPrice,
      getTotalGiftCardPrice,
      getTotalGiftCardFormattedPrice,
      toastMessages,
      addToastMessage,
      removeToastMessage,
      resetAppState,
      selectedSpots,
      product,
      canPayServiceWithProvider,
      canPayProductWithProvider,
      canPayGiftCardWithProvider,
      giftCard,
      giftCardDesign,
      giftCardMessage,
      subscriptionCustomForm,
      serviceCustomForm,
    ]
  );

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const configId = getUrlParams().configId;
    if (!configId) {
      return setIsReady(true);
    }

    widgetConfigApi
      .getConfig(configId)
      .then((configResponse) => {
        const config = JSON.parse(configResponse.config) as BookingOptionsState;
        if (config.selectedDuration) {
          setSelectedDuration(config.selectedDuration);
        }
        if (config.selectedParticipants) {
          setSelectedParticipants(config.selectedParticipants);
        }
        if (config.selectedTickets) {
          setSelectedTickets(config.selectedTickets);
        }
        if (config.selectedCalendar) {
          setSelectedCalendar(config.selectedCalendar);
        }
        if (config.selectedTimeslot) {
          setSelectedTimeslot(config.selectedTimeslot);
        }
        if (config.selectedDate) {
          setSelectedDate(new Date(config.selectedDate));
        }
        setIsReady(true);
      })
      .catch(() => {
        resetAppState();
        setIsReady(true);
      });
  }, [
    isReady,
    setIsReady,
    resetAppState,
    setSelectedDuration,
    setSelectedParticipants,
    setSelectedTickets,
    setSelectedCalendar,
    setSelectedTimeslot,
    setSelectedDate,
  ]);

  return (
    <appStateContext.Provider value={context}>
      {isReady ? (
        children
      ) : (
        <div className="flex justify-center items-center h-screen">
          <div className="text-2xl">Loading...</div>
        </div>
      )}
    </appStateContext.Provider>
  );
};
