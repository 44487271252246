import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Route, Routes, useParams} from "react-router-dom";
import {NotFoundPage} from "../../pages/notFoundPage";
import classNames from "classnames";
import {
  AddCommentIcon, Button, ButtonSize,
  IconTheme, LadyWithPhoneIcon, LadyWithShoppingErrorIcon,
  Spinner,
  StarEmptyIcon,
  StarIcon, useRequest
} from "@bookla-app/bookla-react-components";
import {getErrorMessage} from "../../../utils/getRequestError";
import {Input} from "../../molecules/input";
import {Form, FormikProps, withFormik} from "formik";
import {IntlShape, useIntl} from "react-intl";
import * as Yup from "yup";
import {reservationApi} from "../../../api/reservation";
import {ToastMessageProps} from "../../molecules/toast";
import {useAppState} from "../../../contexts/AppStateProvider";
import {Header} from "../../molecules/header";
import {ReservationDetails, ReservationReviewInfo} from "../../../types/reservation";
import {logger} from "../../../utils/logger";

const COMMENT_MAX_LENGTH = 500;

interface LeaveReservationReviewValues {
  rating: number;
  comment: string;
  reservationId?: string;
  sig?: string;
  onFinished?: () => void;
}

interface LeaveReservationReviewProps {
  intl: IntlShape;
  addToastMessage: (message: Omit<ToastMessageProps, 'id'>) => void;
}

export const LeaveReviewPage: React.FC< LeaveReservationReviewProps & FormikProps<LeaveReservationReviewValues>> = ({
  intl,
  isSubmitting,
  isValid,
  values,
  setFieldValue,
  setFieldTouched,
}) => {
  const reservationId = useParams<{ reservationId?: string }>().reservationId;
  const sign = useParams<{ sign?: string }>().sign;
  const stars = useMemo(() => new Array(5).fill(0), []);
  const [hoverRating, setHoverRating] = useState(0);
  const [reviewInfo, setReviewInfo] = useState<ReservationReviewInfo | undefined>(undefined);
  const [pageError, setPageError] = useState<string>();
  const [finished, setFinished] = useState(false);

  const setRating = (rating: number) => {
    setFieldTouched('rating', true);
    setFieldValue('rating', rating);
    values.reservationId = reservationId;
    values.sig = sign;
    values.onFinished = () => {
      setFinished(true);
    }
  };

  const getData = useCallback(async () => {
    if (!reservationId || !sign) {
      return undefined;
    }
    return reservationApi
      .getReviewInfo(reservationId, sign)
  }, [reservationId, sign]);

  const onError = useCallback(
    (error: unknown) => {
      setPageError(getErrorMessage(error, intl));
      logger.error(error);
    },
    [intl, setPageError]
  );

  const [isLoading] = useRequest({
    getData,
    onSuccess: setReviewInfo,
    locale: intl.locale,
    onError,
  });

  useEffect(() => {
    setPageError(undefined);
  }, [setPageError]);

  return (
    <Form className="page page-sm">
      <Header />
      <main className="flex flex-col flex-grow px-5 pb-5 md:px-10">
        {isLoading && <Spinner />}
        {!isLoading && !!pageError && (
          <div className="flex flex-col items-center my-auto">
            <LadyWithShoppingErrorIcon
              fullSize
              className="max-w-sm max-h-96 -mt-8 -mb-4"
            />
            <p className="text-center font-semibold">{pageError}</p>
          </div>
        )}
        {(finished || reviewInfo?.isReviewSubmitted === true) && (
          <div className="flex flex-col items-center my-auto">
            <LadyWithPhoneIcon
              fullSize
              className="max-w-sm max-h-96 -mt-8 -mb-4"
            />
            <h1 className="font-semibold text-xl mb-1 text-center">
              {intl.formatMessage({
                id: 'leave_review_thanks_title',
              })}
            </h1>
            <p className="text-sm text-center mb-2 p-2">
              {intl.formatMessage({
                id: 'leave_review_thanks_description',
              })}
            </p>
          </div>
        )}
        {!!reviewInfo && !finished && !reviewInfo.isReviewSubmitted && (
          <>
            <div className="overflow-y-auto overflow-x-hidden">
              <h1 className="font-semibold text-xl mb-1 text-center">
                {intl.formatMessage({
                  id: 'my_reservations_modal_reservation_review_cta',
                })}
              </h1>
              <p className="text-sm text-center mb-2 p-2">
                {intl.formatMessage(
                  {
                    id: 'my_reservations_modal_reservation_review_description',
                  },
                  { companyName: reviewInfo.companyName }
                )}
              </p>
              <div className="flex flex-row items-center justify-center mb-2">
                {stars.map((_, i) => {
                  const value = i + 1;
                  return (
                    <button
                      type="button"
                      onClick={() => setRating(value)}
                      key={`star-${i}`}
                      onMouseEnter={() => setHoverRating(value)}
                      onMouseLeave={() => setHoverRating(0)}
                    >
                      {(!hoverRating && values.rating >= value) ||
                      hoverRating >= value ? (
                        <StarIcon className="w-9 h-9" fill={IconTheme.Primary} />
                      ) : (
                        <StarEmptyIcon
                          className="w-9 h-9"
                          fill={IconTheme.Primary}
                        />
                      )}
                    </button>
                  );
                })}
              </div>
              <p className="text-xs text-center text-secondary mb-2">
                {intl.formatMessage(
                  {
                    id: 'my_reservations_modal_reservation_review_rating_label',
                  },
                  { companyName: "Company title" }
                )}
              </p>
              <div className="flex flex-row mr-1">
                <AddCommentIcon
                  fill={IconTheme.Primary}
                  className="w-6 h-6 mt-5 sm:mt-6"
                />
                <Input
                  component="textarea"
                  rows={2}
                  maxLength={COMMENT_MAX_LENGTH}
                  name="comment"
                  label="my_reservations_modal_reservation_review_comment_label"
                  withDesktopAnimation
                  withDivider={false}
                  withErrors={false}
                  inputClassName="border-2 border-accent rounded-lg"
                />
              </div>
              <small className="block w-full text-right text-xs text-secondary pr-4 mt-1">
                {values.comment?.length ?? 0}/{COMMENT_MAX_LENGTH}
              </small>
              <p className="text-xs text-center text-secondary">
                {intl.formatMessage(
                  {
                    id: 'my_reservations_modal_reservation_review_disclaimer',
                  },
                  { companyName: reviewInfo.companyName }
                )}
              </p>
            </div>
            <Button
              type="submit"
              disabled={isSubmitting || !isValid}
              size={ButtonSize.sm}
              className="my-4"
              isLoading={isSubmitting}
            >
              {intl.formatMessage({
                id: 'my_reservations_modal_reservation_review_add_cta',
              })}
            </Button>
          </>
        )}
      </main>
    </Form>
  );
}

export const LeaveReservationReview = withFormik<
  LeaveReservationReviewProps,
  LeaveReservationReviewValues
>({

  mapPropsToValues: () => ({
    rating: 0,
    comment: '',
  }),
  validationSchema: Yup.object().shape({
    rating: Yup.number().required().min(1).max(5),
    comment: Yup.string().max(COMMENT_MAX_LENGTH),
  }),
  handleSubmit: async (
    values,
    {
      setSubmitting,
      props: { addToastMessage, intl },
    }
  ) => {
    setSubmitting(true);
    try {
      const review = await reservationApi.addReservationReview(values.reservationId ?? '', values.sig ?? '',{
        rating: values.rating,
        text: values.comment,
      });
      setSubmitting(false);
      values.onFinished?.();

    } catch (error) {
      setSubmitting(false);
      addToastMessage({ message: getErrorMessage(error, intl) });
    }
  },
  validateOnMount: true,
})(LeaveReviewPage);

export const LeaveReviewRoutes: React.FC = () => {
  const { addToastMessage } = useAppState();
  const intl = useIntl();
  return (
    <Routes>
      <Route path="/:reservationId/:sign" element={<LeaveReservationReview addToastMessage={addToastMessage} intl={intl} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
