import { Reducer, useCallback, useMemo, useReducer } from 'react';
import { Calendar } from '../types/calendar';
import { SelectedTickets } from '../types/ticket';
import { ParsedTimeslot } from '../types/timeslot';
import { sum } from '@bookla-app/bookla-react-components';
import { getServiceDefaultSelectedDate } from './widgetOptions';
import { AuthUser } from '../contexts/authProvider';

export interface BookingOptionsState {
  selectedDate: Date;
  selectedCalendar: Calendar | null;
  selectedDuration: number | null;
  selectedTimeslot: ParsedTimeslot | null;
  selectedParticipants: number;
  selectedTickets: SelectedTickets;
}

export interface BookingOptionsUser {
  user: AuthUser | null;
}

export type BookingOptionsWithUser = BookingOptionsState & BookingOptionsUser;

interface BookingOptionsDispatch {
  setSelectedDate: (date: Date) => void;
  setSelectedCalendar: (calendar: Calendar | null) => void;
  setSelectedDuration: (duration: number) => void;
  setSelectedTimeslot: (timeslot: ParsedTimeslot | null) => void;
  setSelectedParticipants: (participants: number) => void;
  setSelectedTickets: (tickets: SelectedTickets) => void;
  resetBookingState: () => void;
  resetSelectedDate: () => void;
}

interface BookingOptionsSelectors {
  today: Date;
  totalSelectedTickets: number;
  hasFormChanges: boolean;
}

export type BookingOptions = BookingOptionsState &
  BookingOptionsDispatch &
  BookingOptionsSelectors;

const Actions = {
  SetSelectedDate: 'setSelectedDate',
  SetSelectedCalendar: 'setSelectedCalendar',
  SetSelectedDuration: 'setSelectedDuration',
  SetSelectedTimeslot: 'setSelectedTimeslot',
  SetSelectedParticipants: 'setSelectedParticipants',
  SetSelectedTickets: 'setSelectedTickets',
  ResetBookingState: 'resetBookingState',
  ResetSelectedDate: 'resetSelectedDate',
} as const;

const setSelectedDateAction = (
  payload: BookingOptionsState['selectedDate']
) => ({
  type: Actions.SetSelectedDate,
  payload,
});
const setSelectedCalendarAction = (
  payload: BookingOptionsState['selectedCalendar']
) => ({
  type: Actions.SetSelectedCalendar,
  payload,
});
const setSelectedDurationAction = (
  payload: BookingOptionsState['selectedDuration']
) => ({
  type: Actions.SetSelectedDuration,
  payload,
});
const setSelectedTimeslotAction = (
  payload: BookingOptionsState['selectedTimeslot']
) => ({
  type: Actions.SetSelectedTimeslot,
  payload,
});
const setSelectedParticipantsAction = (
  payload: BookingOptionsState['selectedParticipants']
) => ({
  type: Actions.SetSelectedParticipants,
  payload,
});
const setSelectedTicketsAction = (
  payload: BookingOptionsState['selectedTickets']
) => ({
  type: Actions.SetSelectedTickets,
  payload,
});
const resetBookingStateAction = () => ({ type: Actions.ResetBookingState });
const resetSelectedDateAction = () => ({ type: Actions.ResetSelectedDate });

type BookingOptionsActions =
  | ReturnType<typeof setSelectedDateAction>
  | ReturnType<typeof setSelectedCalendarAction>
  | ReturnType<typeof setSelectedDurationAction>
  | ReturnType<typeof setSelectedTimeslotAction>
  | ReturnType<typeof setSelectedParticipantsAction>
  | ReturnType<typeof setSelectedTicketsAction>
  | ReturnType<typeof resetBookingStateAction>
  | ReturnType<typeof resetSelectedDateAction>;

const reducer: Reducer<BookingOptionsState, BookingOptionsActions> = (
  state,
  action
) => {
  switch (action.type) {
    case Actions.SetSelectedDate: {
      return { ...state, selectedDate: action.payload, selectedTimeslot: null };
    }
    case Actions.SetSelectedCalendar: {
      return {
        ...state,
        selectedCalendar: action.payload,
        selectedTimeslot: null,
      };
    }
    case Actions.SetSelectedDuration: {
      return {
        ...state,
        selectedDuration: action.payload,
        selectedTimeslot: null,
      };
    }
    case Actions.SetSelectedParticipants: {
      return {
        ...state,
        selectedParticipants: action.payload,
        selectedTimeslot: null,
      };
    }
    case Actions.SetSelectedTickets: {
      return {
        ...state,
        selectedTickets: action.payload,
        selectedTimeslot: null,
      };
    }
    case Actions.SetSelectedTimeslot: {
      return { ...state, selectedTimeslot: action.payload };
    }
    case Actions.ResetBookingState: {
      return getInitialBookingOptionsState();
    }
    case Actions.ResetSelectedDate: {
      return {
        ...state,
        selectedDate: getInitialBookingOptionsState().selectedDate,
      };
    }
    default:
      return state;
  }
};

const defaultBookingOptionsState: BookingOptionsState = {
  selectedDate: new Date(),
  selectedCalendar: null,
  selectedDuration: null,
  selectedParticipants: 1,
  selectedTimeslot: null,
  selectedTickets: {},
};

export const getInitialBookingOptionsState = (): BookingOptionsState => ({
  ...defaultBookingOptionsState,
  ...getServiceDefaultSelectedDate(),
});

export const useBookingOptions = (): BookingOptions => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialBookingOptionsState()
  );
  const today = useMemo(() => new Date(), []);

  const setSelectedDate = useCallback(
    (date: BookingOptionsState['selectedDate']) =>
      dispatch(setSelectedDateAction(date)),
    []
  );
  const setSelectedDuration = useCallback(
    (duration: BookingOptionsState['selectedDuration']) =>
      dispatch(setSelectedDurationAction(duration)),
    []
  );
  const setSelectedParticipants = useCallback(
    (participants: BookingOptionsState['selectedParticipants']) =>
      dispatch(setSelectedParticipantsAction(participants)),
    []
  );
  const setSelectedCalendar = useCallback(
    (calendar: BookingOptionsState['selectedCalendar']) =>
      dispatch(setSelectedCalendarAction(calendar)),
    []
  );
  const setSelectedTimeslot = useCallback(
    (timeslot: BookingOptionsState['selectedTimeslot']) =>
      dispatch(setSelectedTimeslotAction(timeslot)),
    []
  );
  const setSelectedTickets = useCallback(
    (tickets: BookingOptionsState['selectedTickets']) =>
      dispatch(setSelectedTicketsAction(tickets)),
    []
  );
  const resetBookingState = useCallback(
    () => dispatch(resetBookingStateAction()),
    []
  );

  const resetSelectedDate = useCallback(
    () => dispatch(resetSelectedDateAction()),
    []
  );

  const totalSelectedTickets = useMemo(
    () => sum(Object.values(state.selectedTickets)),
    [state.selectedTickets]
  );

  const hasFormChanges = useMemo(() => {
    const initialState = getInitialBookingOptionsState();

    return (
      initialState.selectedDate !== state.selectedDate ||
      initialState.selectedCalendar?.id !== state.selectedCalendar?.id ||
      initialState.selectedDuration !== state.selectedDuration ||
      initialState.selectedParticipants !== state.selectedParticipants ||
      Object.keys(state.selectedTickets).some(
        (key) =>
          initialState.selectedTickets[key] !== state.selectedTickets[key]
      ) ||
      initialState.selectedTimeslot?.startTime !==
        state.selectedTimeslot?.startTime
    );
  }, [state]);

  return {
    today,
    ...state,
    setSelectedDate,
    setSelectedCalendar,
    setSelectedDuration,
    setSelectedTimeslot,
    setSelectedParticipants,
    setSelectedTickets,
    resetBookingState,
    resetSelectedDate,
    totalSelectedTickets,
    hasFormChanges,
  };
};
