import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  productToIconMap,
  productToIconColorMap,
  productToBgColorMap,
} from 'utils/productMappings';
import { BaseProduct } from '../../../types/product';
import {
  FormattedCurrency,
  ListItem,
  Thumbnail,
} from '@bookla-app/bookla-react-components';
import { getPath, ROUTES } from '../../../utils/routes';
import { useIntl } from 'react-intl';

interface ProductItemProps extends BaseProduct {
  withDivider?: boolean;
}

export const ProductItem: React.FC<ProductItemProps> = ({
  title,
  subtitle,
  price,
  currency,
  id,
  type,
  withDivider = true,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const Icon = productToIconMap[type];
  const iconColor = productToIconColorMap[type];

  const goToService = () => {
    navigate(getPath(ROUTES.PRODUCT, { productId: id }));
  };

  return (
    <ListItem
      onClick={goToService}
      leftComponent={
        <Thumbnail
          type="icon"
          bgColor={productToBgColorMap[type]}
          iconColor={iconColor}
          icon={Icon}
        />
      }
      title={title}
      subTitle={subtitle}
      rightComponent={
        <div className="flex flex-col text-right justify-between">
          <span className="font-medium text-sm sm:text-base">
            <FormattedCurrency value={price} currency={currency} intl={intl} />
          </span>
        </div>
      }
      withDivider={withDivider}
      titleClassName="text-primary text-sm sm:text-base font-medium"
    />
  );
};
