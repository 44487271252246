import {CompanyResponse} from "../types/company";

export const parseProducts = (
  data: Pick<CompanyResponse, 'products'>,
  visibleProductIds?: string[]
): {
  products: CompanyResponse['products'];
} => {
  if(!visibleProductIds || visibleProductIds.length === 0) {
    return {
      products: data.products
    }
  }

  const unsortedProducts = (data?.products || []).sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  const products = unsortedProducts.filter((product) =>
    visibleProductIds && visibleProductIds.length > 0 ? visibleProductIds.includes(product.id) : true
  );

  return {
    products,
  };
}