import apiAxios from './apiAxios';
import authApi, { REFRESH_TOKEN_API_URL } from './auth';
import { AuthToken } from '../types/user';
import { AxiosError } from 'axios';
import { STORAGE_KEYS } from '../utils/storage';
import { storage } from '@bookla-app/bookla-react-components';
import { wait } from 'utils/promises';

const MAX_RETRIES = 2;

export default class JwtService {
  private isAlreadyFetchingAccessToken = false;
  private tries = 0;
  private unauthorizedSubs: { (): void }[] = [];

  constructor() {
    apiAxios.interceptors.request.use(
      (config) => {
        const accessToken = this.getAccessToken();
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    apiAxios.interceptors.response.use(
      // Any status code that lie within the range of 2xx cause this function to trigger
      (response) => response.data,
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      (error: AxiosError<unknown>) => {
        if (error.response?.status !== 401) {
          return Promise.reject(error);
        }

        return this.retryWithNewAccessToken(error);
      }
    );
  }

  private async retryOnceAccessTokenIsReady(error: AxiosError<unknown>) {
    while (this.isAlreadyFetchingAccessToken) {
      await wait(50);
    }
    const originalRequestWithNewAuth = { ...error.config };
    const accessToken = this.getAccessToken();
    if (accessToken) {
      originalRequestWithNewAuth.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      delete originalRequestWithNewAuth.headers.Authorization;
    }
    return apiAxios(originalRequestWithNewAuth);
  }

  private async retryWithNewAccessToken(error: AxiosError<unknown>) {
    if (error.config.url === REFRESH_TOKEN_API_URL) {
      return Promise.reject(error);
    }
    if (this.isAlreadyFetchingAccessToken) {
      return this.retryOnceAccessTokenIsReady(error);
    }
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.onUnauthorized();
      return Promise.reject(error);
    }
    if (this.tries === MAX_RETRIES - 1) {
      this.tries = 0;
      return Promise.reject(error);
    }

    this.isAlreadyFetchingAccessToken = true;
    this.tries++;

    try {
      const auth = await authApi.refreshToken(refreshToken);
      this.isAlreadyFetchingAccessToken = false;
      this.setAuth(auth);
      const originalRequest = error.config;
      return apiAxios(originalRequest);
    } catch (refreshError) {
      this.isAlreadyFetchingAccessToken = false;
      this.onUnauthorized();
      return Promise.reject(refreshError);
    }
  }

  private onUnauthorized() {
    this.unauthorizedSubs = this.unauthorizedSubs.filter((callback) =>
      callback()
    );
  }

  private getAccessToken() {
    return storage.getCookie(STORAGE_KEYS.accessToken);
  }

  private getRefreshToken() {
    return storage.getCookie(STORAGE_KEYS.refreshToken);
  }

  public addOnUnauthorizedSubscriber(callback: { (): void }) {
    this.unauthorizedSubs.push(callback);
  }

  public setAuth(auth: AuthToken) {
    storage.setCookie(
      STORAGE_KEYS.accessToken,
      auth.accessToken,
      new Date(auth.expiresAt)
    );
    storage.setCookie(
      STORAGE_KEYS.refreshToken,
      auth.refreshToken,
      new Date(auth.refreshTokenExpiresAt)
    );
  }

  public clear() {
    storage.removeCookie(STORAGE_KEYS.accessToken);
    storage.removeCookie(STORAGE_KEYS.refreshToken);
  }

  public hasAuth() {
    return !!this.getAccessToken() || !!this.getRefreshToken();
  }
}
