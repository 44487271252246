import { IconProps, Path, Svg } from '@bookla-app/bookla-react-components';

export const CartIcon: React.FC<{ count: number } & IconProps> = ({
  count,
  ...props
}) => {
  return (
    <div className="relative w-[30px] h-[30px] pl-[2px] pt-2 pb-[6px]">
      <Svg width="16" height="16" viewBox="0 0 16 16" {...props}>
        <Path
          d="M5.33152 11.9997C4.59846 11.9997 4.00535 12.5997 4.00535 13.333C4.00535 14.0663 4.59846 14.6663 5.33152 14.6663C6.06458 14.6663 6.66436 14.0663 6.66436 13.333C6.66436 12.5997 6.06458 11.9997 5.33152 11.9997ZM1.33301 1.99967C1.33301 2.36634 1.6329 2.66634 1.99943 2.66634H2.66585L5.06496 7.72634L4.16529 9.35301C3.6788 10.2463 4.31857 11.333 5.33152 11.333H12.6621C13.0287 11.333 13.3286 11.033 13.3286 10.6663C13.3286 10.2997 13.0287 9.99967 12.6621 9.99967H5.33152L6.06458 8.66634H11.0294C11.5292 8.66634 11.9691 8.39301 12.1956 7.97967L14.5814 3.65301C14.828 3.21301 14.5081 2.66634 14.0016 2.66634H4.13863L3.69213 1.71301C3.5855 1.47967 3.34559 1.33301 3.09235 1.33301H1.99943C1.6329 1.33301 1.33301 1.63301 1.33301 1.99967ZM11.9957 11.9997C11.2627 11.9997 10.6695 12.5997 10.6695 13.333C10.6695 14.0663 11.2627 14.6663 11.9957 14.6663C12.7288 14.6663 13.3286 14.0663 13.3286 13.333C13.3286 12.5997 12.7288 11.9997 11.9957 11.9997Z"
          {...props}
        />
      </Svg>
      <div className="w-5 h-5 right-0 top-0 absolute bg-status-error rounded-2xl border-2 border-white text-center text-white text-xs font-semibold flex items-center justify-center">
        {count > 9 ? (
          <>
            9<span className="text-[8px]">+</span>
          </>
        ) : (
          count
        )}
      </div>
    </div>
  );
};
