import {ReactNode} from "react";

interface EmptyPriceProps {
  showPrice: boolean;
  content: ReactNode
}

export const EmptyPrice: React.FC<EmptyPriceProps> = ({showPrice, content}) => {
  return (
    <>
      {showPrice ? (
        content
      ) : (
        <div></div>
      )}
    </>
  );
};
