import { companyApi } from 'api/company';
import { useState, useEffect } from 'react';
import { TicketBundle } from 'types/company';
import { logger } from './logger';

export const useTicketBundleDetails = ({
  companyId,
  ticketsBundleId,
}: {
  companyId: string;
  ticketsBundleId?: string;
}) => {
  const [ticketBundleDetails, setTicketBundleDetails] =
    useState<TicketBundle>();

  useEffect(() => {
    if (!ticketsBundleId) {
      return;
    }
    companyApi
      .getTicketBundleDetails(companyId, ticketsBundleId)
      .then(setTicketBundleDetails)
      .catch((err) => {
        logger.error(err);
      });
  }, [companyId, ticketsBundleId]);

  return { ticketBundleDetails };
};
