import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CompanyService } from '../../../types/company';
import {
  getHoursAndMinutes,
  FormattedCurrency,
  ListItem,
  Thumbnail,
} from '@bookla-app/bookla-react-components';
import { getPath, ROUTES } from '../../../utils/routes';
import {widgetOptions} from "../../../utils/widgetOptions";
import {EmptyPrice} from "../emptyPrice";

interface ServiceItemProps
  extends Pick<
    CompanyService,
    | 'thumbnail'
    | 'title'
    | 'duration'
    | 'price'
    | 'currency'
    | 'id'
    | 'originalPrice'
  > {
  withDivider?: boolean;
}

export const ServiceItem: React.FC<ServiceItemProps> = ({
  thumbnail,
  title,
  duration,
  price,
  currency,
  id,
  originalPrice,
  withDivider = true,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const goToService = () => {
    navigate(getPath(ROUTES.SERVICE, { serviceId: id }));
  };

  return (
    <ListItem
      onClick={goToService}
      leftComponent={<Thumbnail type="image" url={thumbnail} />}
      title={title}
      subTitle={getHoursAndMinutes(duration, intl)}
      rightComponent={
        <EmptyPrice showPrice={!widgetOptions.hideServicePrices} content={
          <div className="flex flex-col text-right justify-between">
              <span className="font-medium text-sm sm:text-base">
                <FormattedCurrency value={price} currency={currency} intl={intl} />
              </span>
              {originalPrice !== price && (
                <span className="text-secondary text-xs sm:text-sm line-through ml-1">
                  <FormattedCurrency
                    value={originalPrice}
                    currency={currency}
                    intl={intl}
                  />
                </span>
              )}
          </div>
        }/>
      }
      withDivider={withDivider}
    />
  );
};
