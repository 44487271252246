import apiAxios from './apiAxios';
import {WidgetCheckoutConfig} from "../types/widgetConfig";

const BASE_ROUTE = '/api/feed/widgetConfig';

export const widgetConfigApi = {
  getConfig: async (configId: string): Promise<WidgetCheckoutConfig> =>
    apiAxios.get(`${BASE_ROUTE}/${configId}`),
  saveConfig: async (config: string): Promise<WidgetCheckoutConfig> =>
    apiAxios.post(`${BASE_ROUTE}`, {config: config}),
};