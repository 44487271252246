import { SupportedLanguages } from '@bookla-app/bookla-react-components';
import { getPath, ROUTES } from './routes';

const getMarketingBooklaSiteSupportedLang = (language: SupportedLanguages) => {
  return [
    SupportedLanguages.en,
    SupportedLanguages.ru,
    SupportedLanguages.lv,
    SupportedLanguages.es,
  ].includes(language)
    ? language
    : SupportedLanguages.en;
};

export const getBooklaLink = (language: SupportedLanguages) =>
  `https://bookla.com/${getMarketingBooklaSiteSupportedLang(language)}`;

export const getTermsAndConditionsLink = (language: SupportedLanguages) =>
  `${getBooklaLink(language)}/terms-and-conditions`;

export const getPrivacyPolicyLink = (language: SupportedLanguages) =>
  `${getBooklaLink(language)}/privacy-policy`;

export const getPaymentPolicyLink = (language: SupportedLanguages) =>
  getTermsAndConditionsLink(language) + '#section5';

export const getGoogleMapsLink = (latitude: string, longitude: string) =>
  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    `${latitude},${longitude}`
  )}`;

export const getGoogleCalendarCreateEventLink = ({
  serviceName,
  startDate,
  endDate,
  latitude,
  longitude,
}: {
  serviceName: string;
  startDate: string;
  endDate: string;
  latitude: string;
  longitude: string;
}) => {
  const params = new URLSearchParams();
  params.set('text', serviceName);

  const formattedStartDate = new Date(startDate)
    .toISOString()
    .replace(/[^\w\s]/gi, '');
  const formattedEndDate = new Date(endDate)
    .toISOString()
    .replace(/[^\w\s]/gi, '');
  params.set('dates', `${formattedStartDate}/${formattedEndDate}`);

  params.set('location', `${latitude},${longitude}`);

  return `https://calendar.google.com/calendar/u/0/r/eventedit?${params.toString()}`;
};

export const getExternalServiceLink = (companyId: string, serviceId: string) =>
  `${window.location.origin}${getPath(ROUTES.SERVICE, {
    companyId,
    serviceId,
  })}`;
