import { storage } from '@bookla-app/bookla-react-components';
import { isInIframe } from 'utils/isInIframe';
import { STORAGE_KEYS } from 'utils/storage';

export interface CookieBannerSettings {
  isStatisticsSelected: boolean;
}

export const hasCookieConsent = (
  key: keyof CookieBannerSettings
): boolean | undefined => {
  if (isInIframe()) {
    return true;
  }
  try {
    const cookie = storage.getCookie(STORAGE_KEYS.cookieConsent);
    const parsedCookieSettings = cookie
      ? (JSON.parse(cookie) as CookieBannerSettings)
      : undefined;
    return parsedCookieSettings?.[key];
  } catch (error) {
    return false;
  }
};
