import classNames from 'classnames';
import { FormError } from '../../atoms/formError';
import { Field, FieldConfig, useFormikContext } from 'formik';
import React, { RefObject, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormattedMessageProps } from 'types/common';
import get from 'lodash/get';

type FieldProps = JSX.IntrinsicElements['input'] &
  Pick<JSX.IntrinsicElements['textarea'], 'cols' | 'rows'> &
  FieldConfig;

export interface InputProps extends Omit<FieldProps, 'innerRef'> {
  /*
   * @deprecated use `labelText` instead
   */
  label?: string | FormattedMessageProps;
  labelText?: string;
  showOptional?: boolean;
  focusedShowOptional?: boolean;
  focusedLabel?: string | FormattedMessageProps;
  showLabel?: boolean;
  name: string;
  innerRef?: RefObject<HTMLInputElement>;
  withDesktopAnimation?: boolean;
  withDivider?: boolean;
  className?: string;
  inputClassName?: string;
  withErrors?: boolean;
}

export const Input: React.FC<InputProps> = ({
  label,
  labelText,
  name,
  focusedLabel,
  className,
  showOptional = false,
  focusedShowOptional = true,
  showLabel = true,
  withDesktopAnimation = false,
  withDivider = true,
  withErrors = true,
  disabled,
  inputClassName,
  ...rest
}) => {
  const intl = useIntl();
  const [isFocused, setIsFocused] = useState(false);
  const { values, handleBlur, errors } =
    useFormikContext<Record<string, unknown>>();

  const hasError = !!errors[name];
  const shouldAnimate =
    isFocused || !!get(values, name) || get(values, name) === 0;
  const labelTranslation =
    typeof label === 'string' ? (
      <FormattedMessage id={label} />
    ) : label ? (
      <FormattedMessage {...label} />
    ) : (
      labelText
    );
  const focusedLabelTranslation =
    typeof focusedLabel === 'string' ? (
      <FormattedMessage id={focusedLabel} />
    ) : focusedLabel ? (
      <FormattedMessage {...focusedLabel} />
    ) : undefined;

  const onBlur = useCallback(
    (e: React.FocusEvent) => {
      handleBlur(e);
      if (showLabel) {
        setIsFocused(false);
      }
    },
    [handleBlur, showLabel]
  );

  const inputComponent = (
    <Field
      {...rest}
      id={name}
      name={name}
      className={classNames(
        inputClassName,
        'w-full py-1 mt-4 pl-1 -ml-1 placeholder:text-sm',
        withDesktopAnimation ? 'sm:mt-5' : 'sm:mt-0',
        disabled && 'text-disabled'
      )}
      onFocus={showLabel ? () => setIsFocused(true) : undefined}
      onBlur={onBlur}
      aria-label={showLabel ? undefined : labelTranslation}
      aria-invalid={hasError}
      disabled={disabled}
    />
  );

  return (
    <div className={classNames(className, 'w-full')}>
      {showLabel ? (
        <label className="relative flex flex-col mx-1 px-1 overflow-hidden">
          <span
            className={classNames(
              'transition-transform origin-left transform absolute text-sm sm:text-base w-full whitespace-nowrap',
              !withDesktopAnimation &&
                'sm:transition-none sm:transform-none sm:relative sm:text-xs sm:text-secondary',
              shouldAnimate
                ? 'translate-y-0 scale-75'
                : 'translate-y-full overflow-hidden text-ellipsis text-secondary',
              !disabled && shouldAnimate && 'text-accent',
              disabled && 'text-secondary'
            )}
          >
            {shouldAnimate && focusedLabelTranslation
              ? focusedLabelTranslation
              : labelTranslation}
            {showOptional &&
              (shouldAnimate ? focusedShowOptional : true) &&
              ` (${intl.formatMessage({ id: 'user_interaction_optional' })})`}
          </span>
          {inputComponent}
        </label>
      ) : (
        inputComponent
      )}
      {withDivider && <hr className="mt-1" />}
      {withErrors && (
        <div className="mx-2">
          <FormError name={name} />
        </div>
      )}
    </div>
  );
};
