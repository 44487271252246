import { useIntl } from 'react-intl';
import { ExternalLink } from 'components/atoms/ExternalLink';
import { useLocale } from 'contexts/translationsProvider';

interface AppleStoreButtonProps {}

export const AppleStoreButton: React.FC<AppleStoreButtonProps> = () => {
  const { language } = useLocale();
  const intl = useIntl();

  return (
    <ExternalLink
      href="https://apps.apple.com/us/app/bookla-book-services-nearby/id1495988963"
      className="flex-shrink-0 block h-7 sm:h-11 m-app-button sm:m-app-button-sm"
    >
      <img
        src={process.env.PUBLIC_URL + `/appleStoreBadges/${language}/dark.svg`}
        alt={intl.formatMessage({
          id: 'profile_page_apple_store_aria_label',
        })}
        className="h-full"
      />
    </ExternalLink>
  );
};
