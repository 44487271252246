import { cartApi } from 'api/cart';
import { CartModal } from 'components/organisms/cartModal';
import { useAppState } from 'contexts/AppStateProvider';
import { useAuth } from 'contexts/authProvider';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { CartCountResponse, CartItemResponse } from 'types/cart';
import { getErrorMessage } from 'utils/getRequestError';
import { logger } from 'utils/logger';
import {
  UnparsedReservationDataParams,
  getReservationData,
} from 'utils/reservations';
import { useModalState } from 'utils/useModalState';
import { widgetOptions } from 'utils/widgetOptions';

interface CartState {
  cart: CartCountResponse;
  cartItems: CartItemResponse[];
  addToCart: (data: UnparsedReservationDataParams) => Promise<void>;
  deleteCartItem: (id: string) => Promise<void>;
  timerSeconds: number;
  formattedTimer: string;
  showCart: () => void;
  resetCart: () => void;
}

const emptyCart: CartCountResponse = {
  companyId: '',
  confirmedAt: '',
  expiresAt: '',
  count: 0,
};

const cartContext = createContext<CartState>({
  cart: emptyCart,
  cartItems: [],
  addToCart: () => Promise.resolve(),
  deleteCartItem: () => Promise.resolve(),
  timerSeconds: 0,
  formattedTimer: '',
  showCart: () => {},
  resetCart: () => {},
});

export const useCart = () => useContext(cartContext);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const intl = useIntl();
  const { addToastMessage } = useAppState();
  const { isLoggedIn } = useAuth();
  const [cart, setCart] = useState<CartCountResponse>(emptyCart);
  const [cartItems, setCartItems] = useState<CartItemResponse[]>([]);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const { isModalOpen, openModal, closeModal } = useModalState();

  const formattedTimer = useMemo(() => {
    if (!timerSeconds) {
      return '00:00';
    }
    const minutes = Math.floor(timerSeconds / 60);
    const seconds = timerSeconds % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }, [timerSeconds]);

  const resetState = useCallback(() => {
    setCart(emptyCart);
    setCartItems([]);
    setTimerSeconds(0);
  }, []);

  const updateCart = useCallback(async () => {
    if (!widgetOptions.cartEnabled) {
      return;
    }
    if (!isLoggedIn) {
      return resetState();
    }
    const [updatedCartItems, updatedCart] = await Promise.all([
      cartApi.getCartItems(),
      cartApi.getCartItemsCount(),
    ]);
    setCartItems(updatedCartItems?.items || []);
    setCart(updatedCart);
  }, [isLoggedIn, resetState]);

  const addToCart = useCallback(
    async (data: UnparsedReservationDataParams) => {
      if (!widgetOptions.cartEnabled) {
        return;
      }
      try {
        await cartApi.addToCart(getReservationData(data));
        await updateCart();
        openModal();
      } catch (error) {
        addToastMessage({
          message: getErrorMessage(error, intl),
          autoClose: true,
          reloadPage: false,
        });
      }
    },
    [addToastMessage, intl, openModal, updateCart]
  );

  const deleteCartItem = useCallback(
    async (id: string) => {
      if (!widgetOptions.cartEnabled) {
        return;
      }
      try {
        if (cart.count === 1) {
          await cartApi.clearWholeCart();
        } else {
          await cartApi.deleteCartItem(id);
        }
        await updateCart();
      } catch (error) {
        addToastMessage({
          message: getErrorMessage(error, intl),
          autoClose: true,
          reloadPage: false,
        });
      }
    },
    [addToastMessage, cart.count, intl, updateCart]
  );

  useEffect(() => {
    if (!widgetOptions.cartEnabled) {
      return;
    }
    updateCart().catch((error) => {
      logger.error(error);
    });
  }, [updateCart]);

  useEffect(() => {
    let intervalId = 0;

    if (cart.expiresAt) {
      const updateTimer = () => {
        const totalSeconds = Math.round(
          (new Date(cart.expiresAt).getTime() - Date.now()) / 1000
        );
        setTimerSeconds(totalSeconds);

        if (totalSeconds <= 0) {
          window.clearInterval(intervalId);
          resetState();
        }
      };

      updateTimer();
      intervalId = window.setInterval(updateTimer, 1000);
    } else {
      window.clearInterval(intervalId);
      resetState();
    }

    return () => {
      window.clearInterval(intervalId);
    };
  }, [cart.expiresAt, resetState, updateCart]);

  return (
    <cartContext.Provider
      value={{
        cart,
        cartItems,
        addToCart,
        deleteCartItem,
        timerSeconds,
        formattedTimer,
        showCart: openModal,
        resetCart: resetState,
      }}
    >
      {children}
      {isModalOpen && <CartModal closeModal={closeModal} />}
    </cartContext.Provider>
  );
};
