import { NotFoundPage } from 'components/pages/notFoundPage';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

const GiftCardActivationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "gift-card-activation-page" */ '../components/pages/giftCardActivationPage'
    )
);

export const GiftCardActivationRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<GiftCardActivationPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
