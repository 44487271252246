import {
  ListItem,
  formatCurrency,
  Thumbnail,
  IconTheme,
} from '@bookla-app/bookla-react-components';
import { TrashIcon } from 'components/atoms/icons/TrashIcon';
import { useIntl } from 'react-intl';
import { CartItemResponse } from 'types/cart';
import { getBookingDateAndTime } from 'utils/getBookingDateAndTime';

interface CartItemProps {
  data: CartItemResponse;
  deleteCartItem?: (id: string) => Promise<void>;
}

export const CartItem: React.FC<CartItemProps> = ({ data, deleteCartItem }) => {
  const intl = useIntl();

  const formattedPrice = formatCurrency({
    currency: data.currency,
    value: data.price,
    intl,
  });

  return (
    <ListItem
      title={data.serviceTitle}
      subTitle={
        <div>
          <p>
            {getBookingDateAndTime(
              {
                startTime: data.startDate,
                startTimeFormatted: intl.formatTime(data.startDate, {
                  hourCycle: 'h23',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
                endTimeFormatted: intl.formatTime(data.endDate, {
                  hourCycle: 'h23',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
              },
              intl
            )}
          </p>
          {!!deleteCartItem && (
            <p className="mt-2 font-medium text-primary">{formattedPrice}</p>
          )}
        </div>
      }
      leftComponent={<Thumbnail type="image" url={data.serviceThumbnail} />}
      rightComponent={
        deleteCartItem ? (
          <button
            aria-label={intl.formatMessage({ id: 'cart_remove_item' })}
            className="self-start p-2 bg-accent-variant border border-rainbow-violet-light rounded-lg hover:transform hover:scale-105"
            onClick={() => deleteCartItem(data.id)}
          >
            <TrashIcon fill={IconTheme.Primary} />
          </button>
        ) : (
          <span className="font-medium text-primary">{formattedPrice}</span>
        )
      }
      withDivider
      withPaddingY
      centerItems={false}
    />
  );
};
