import axios, { AxiosRequestConfig } from 'axios';
import { sha256 } from 'js-sha256';
import { getUrlParams } from 'utils/url';
import { v4 as uuidv4 } from 'uuid';
import { STORAGE_KEYS } from '../utils/storage';
import { storage } from '@bookla-app/bookla-react-components';

const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_BOOKLA_API_BASE_URL,
  timeout: 10000,
});

const getDeviceId = () => {
  let deviceId = storage.getLocalStorage(STORAGE_KEYS.deviceId);
  if (deviceId) {
    return deviceId;
  }
  deviceId = uuidv4();
  storage.setLocalStorage(STORAGE_KEYS.deviceId, deviceId);
  return deviceId;
};

const createSig = (config: AxiosRequestConfig, deviceId: string) => {
  const method = config.method?.toUpperCase();
  const path = config.url || '';
  const searchIndex = path.indexOf('?');
  const pathWithoutSearchQuery = escape(
    searchIndex !== -1 ? path.slice(0, searchIndex) : path
  );
  const apiKey = process.env.REACT_APP_BOOKLA_API_KEY;
  const sig = `${method};${pathWithoutSearchQuery};${apiKey};${deviceId}`;
  return sha256(sig);
};

apiAxios.interceptors.request.use(
  async (config) => {
    const deviceId = getDeviceId();
    config.headers.SpendoApp = process.env.REACT_APP_BOOKLA_APP_ORIGIN;
    config.headers.SpendoDeviceID = deviceId;
    config.headers.SpendoSig = createSig(config, deviceId);
    config.headers.common['Accept-Language'] = getUrlParams().lang;
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiAxios;
