import { Spinner } from '@bookla-app/bookla-react-components';
import classNames from 'classnames';
import { useEffect } from 'react';

interface UILoaderProps {
  isBlocking: boolean;
  children: React.ReactElement;
  className?: string;
  /**
   * tailwind classname
   */
  zIndex?: string;
}

export const UILoader: React.FC<UILoaderProps> = ({
  isBlocking,
  className,
  children,
  zIndex = 'z-50',
}) => {
  useEffect(() => {
    let timer: number;
    if (!isBlocking) {
      return;
    }

    timer = window.setTimeout(() => {
      if (document.activeElement && 'blur' in document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    }, 50);

    return () => {
      window.clearTimeout(timer);
    };
  }, [isBlocking]);

  return (
    <div
      className={classNames(
        className,
        isBlocking && 'pointer-events-none bg-gray/20 relative'
      )}
      onKeyDown={isBlocking ? (e) => e.preventDefault() : undefined}
    >
      {children}
      {isBlocking && (
        <div className={zIndex}>
          <Spinner position="absolute" size="sm" />
        </div>
      )}
    </div>
  );
};
