import {
  CustomTheme,
  SupportedLanguages,
  detectLocale,
  getLanguage,
  isLanguageSupported,
} from '@bookla-app/bookla-react-components';
import { isInIframe } from './isInIframe';
import { logger } from './logger';

const URL_SEARCH_PARAMS = {
  isGuestLogin: 'isGuestLogin',
  theme: 'theme',
  serviceDefaultSelectedDate: 'serviceDefaultSelectedDate',
  paymentToken: 'payment_token',
  spotsReserved: 'spotsReserved',
  defaultHomepageTab: 'defaultHomepageTab',
  visibleServiceGroupIds: 'visibleServiceGroupIds',
  visibleProductIds: 'visibleProductIds',
  configId: 'cid',
  hideServicePrices: 'hideServicePrices',
  cartEnabled: 'cartEnabled',
  commentToAppend: 'commentToAppend',
  promoCode: 'promoCode',
};

export enum DefaultHomepageTab {
  Services = 'services',
  Products = 'products',
  GiftCards = 'gift-cards',
}

export interface UrlParams {
  companyId: string;
  lang: SupportedLanguages;
  serviceId?: string;
  productId?: string;
  theme?: Partial<CustomTheme>;
  serviceDefaultSelectedDate?: Date;
  defaultHomepageTab?: DefaultHomepageTab;
  paymentToken?: string;
  spotsReserved?: string;
  giftCardId?: string;
  reservationId?: string;
  visibleServiceGroupIds?: string[];
  hideServicePrices?: boolean;
  cartEnabled?: boolean;
  commentToAppend?: string;
  promoCode?: string;
  configId?: string;
  visibleProductIds?: string[];
}

export const getUrlParams = (href = window.location.href): UrlParams => {
  const url = new URL(href);
  const paths = url.pathname.split('/').slice(1);

  const companyIndex = paths.findIndex((param) => param === 'company');
  let companyId = companyIndex !== -1 ? paths[companyIndex + 1] : undefined;

  if (process.env.REACT_APP_TEST_COMPANY_ID && !isInIframe() && !companyId) {
    companyId = process.env.REACT_APP_TEST_COMPANY_ID;
  }

  const serviceIndex = paths.findIndex((param) => param === 'services');
  const productIndex = paths.findIndex((param) => param === 'products');
  const giftCardIndex = paths.findIndex((param) => param === 'gift-cards');
  const reservationIdIndex = paths.findIndex(
    (param) => param === 'my-reservations'
  );
  const lang = paths[0] as SupportedLanguages | undefined;
  const theme = url.searchParams.get(URL_SEARCH_PARAMS.theme);
  let parsedTheme: UrlParams['theme'];

  if (theme) {
    try {
      parsedTheme = JSON.parse(decodeURIComponent(theme));
    } catch (error) {
      logger.errorOnce(error, 'themeParse');
    }
  }

  const serviceDefaultSelectedDate = url.searchParams.get(
    URL_SEARCH_PARAMS.serviceDefaultSelectedDate
  );
  let parsedServiceDefaultSelectedDate: UrlParams['serviceDefaultSelectedDate'];

  if (serviceDefaultSelectedDate) {
    try {
      parsedServiceDefaultSelectedDate = new Date(
        decodeURIComponent(serviceDefaultSelectedDate)
      );
      // prevents day shift if gmt+
      parsedServiceDefaultSelectedDate.setUTCDate(
        parsedServiceDefaultSelectedDate.getDate()
      );
      parsedServiceDefaultSelectedDate.setUTCHours(0, 0, 0, 0);
    } catch (error) {
      logger.errorOnce(error, 'serviceDefaultSelectedDateParse');
    }
  }

  const visibleProductsIDs = url.searchParams
    .get(URL_SEARCH_PARAMS.visibleProductIds)?.split(',');

  return {
    lang:
      lang && isLanguageSupported(lang) ? lang : getLanguage(detectLocale()),
    companyId: companyId ?? '',
    serviceId: serviceIndex !== -1 ? paths[serviceIndex + 1] : undefined,
    productId: productIndex !== -1 ? paths[productIndex + 1] : undefined,
    giftCardId: giftCardIndex !== -1 ? paths[giftCardIndex + 1] : undefined,
    reservationId:
      reservationIdIndex !== -1 ? paths[reservationIdIndex + 1] : undefined,
    theme: parsedTheme,
    serviceDefaultSelectedDate: parsedServiceDefaultSelectedDate,
    paymentToken:
      url.searchParams.get(URL_SEARCH_PARAMS.paymentToken) ?? undefined,
    spotsReserved:
      url.searchParams.get(URL_SEARCH_PARAMS.spotsReserved) ?? undefined,
    defaultHomepageTab: url.searchParams.get(
      URL_SEARCH_PARAMS.defaultHomepageTab
    ) as DefaultHomepageTab | undefined,
    visibleServiceGroupIds: url.searchParams
      .get(URL_SEARCH_PARAMS.visibleServiceGroupIds)
      ?.split(','),
    visibleProductIds: visibleProductsIDs,
    hideServicePrices:
      url.searchParams.get(URL_SEARCH_PARAMS.hideServicePrices) === 'true',
    cartEnabled: url.searchParams.get(URL_SEARCH_PARAMS.cartEnabled) === 'true',
    commentToAppend: url.searchParams.get(URL_SEARCH_PARAMS.commentToAppend) ?? undefined,
    promoCode: url.searchParams.get(URL_SEARCH_PARAMS.promoCode) ?? undefined,
    configId: url.searchParams.get(URL_SEARCH_PARAMS.configId) ?? undefined,
  };
};

export const isUrlGuestLogin = () => {
  const url = new URL(window.location.href);
  return url.searchParams.get(URL_SEARCH_PARAMS.isGuestLogin) === 'true';
};

export const getUrlGiftCardPaymentState = (): string | null => {
  const url = new URL(window.location.href);
  return url.searchParams.get('statusGroup');
};

export const getUrlWithConfigId = (configId: string, isGuestLogin: boolean, cartEnabled: boolean) => {
  const url = new URL(window.location.href);
  url.searchParams.set(URL_SEARCH_PARAMS.configId, configId);
  if (isGuestLogin) {
    url.searchParams.append('isGuestLogin', 'true');
  }
  if (cartEnabled) {
    url.searchParams.append('cartEnabled', 'true');
  }
  return url.toString();
};

export const hasUrlConfigId = () => {
  const url = new URL(window.location.href);
  return url.searchParams.has(URL_SEARCH_PARAMS.configId);
};

export const getIframeReferrer = () => {
  try {
    if (!isInIframe()) {
      return;
    }
    return document.referrer;
  } catch (error) {
    logger.error(error);
  }
};

export const getIframeParentHost = () => {
  try {
    if (!isInIframe()) {
      return 'bookla.com';
    }
    const href = document.referrer;
    return href.split('/')[2];
  } catch (error) {
    logger.error(error);
    return 'bookla.com';
  }
};
