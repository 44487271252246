import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { analytics } from 'utils/analytics';
import { initialContextFunction } from 'utils/initialContextFunction';
import { logger } from 'utils/logger';
import authApi from '../../api/auth';
import JwtService from '../../api/jwtService';
import {
  GuestLoginParams,
  RegisterUserParams,
  RequestVerificationResponse,
  User,
  UserAuth,
  VerifyCodeSuccessResponse,
} from '../../types/user';
import { STORAGE_KEYS } from '../../utils/storage';
import { storage, useRequest } from '@bookla-app/bookla-react-components';
import { LegalEntity } from 'types/legalEntity';
import { isAxiosError } from 'utils/getRequestError';
import { legalEntityApi } from 'api/legalEntity';
import { useIntl } from 'react-intl';

export type AuthUser = User & { isGuest: boolean };

export interface AuthContext {
  user?: AuthUser;
  phoneNumber?: string;
  verificationCode?: string;
  guestLogin: (params: GuestLoginParams) => Promise<void>;
  logout: () => void;
  login: (phoneNumber: string, password: string) => Promise<void>;
  register: (data: RegisterUserParams) => Promise<void>;
  requestVerification: (props?: {
    fullPhoneNumber?: string;
    alwaysSend?: boolean;
  }) => Promise<RequestVerificationResponse>;
  verifyCode: (verificationCode: string) => Promise<{ registered: boolean }>;
  createPassword: (password: string) => Promise<void>;
  changePassword: (
    currentPassword: string,
    newPassword: string
  ) => Promise<void>;
  tempCode?: string;
  updateUser: (user: User, isGuest: boolean) => void;
  isLoggedIn: boolean;
  legalEntity?: LegalEntity;
  setLegalEntity: React.Dispatch<React.SetStateAction<LegalEntity | undefined>>;
  legalEntityIsReady: boolean;
}

const authContext = createContext<AuthContext>({
  requestVerification: initialContextFunction,
  verifyCode: initialContextFunction,
  guestLogin: initialContextFunction,
  login: initialContextFunction,
  register: initialContextFunction,
  updateUser: initialContextFunction,
  logout: initialContextFunction,
  createPassword: initialContextFunction,
  changePassword: initialContextFunction,
  setLegalEntity: initialContextFunction,
  isLoggedIn: false,
  legalEntityIsReady: false,
});

export const useAuth = () => useContext(authContext);

const jwt = new JwtService();

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const intl = useIntl();
  const [isReady, setIsReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<AuthUser>();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [tempCode, setTempCode] = useState<string>();
  const [legalEntity, setLegalEntity] = useState<LegalEntity>();

  const isLoggedIn = useMemo(
    () => !!user && isAuthenticated,
    [user, isAuthenticated]
  );

  const updateUser = useCallback((user: User, isGuest: boolean) => {
    const updatedUser = { ...user, isGuest };
    storage.setLocalStorage(STORAGE_KEYS.user, JSON.stringify(updatedUser));
    setUser(updatedUser);
  }, []);

  const saveAuth = useCallback(
    (auth: UserAuth, isGuest = false) => {
      jwt.setAuth(auth.token);
      updateUser(auth.user, isGuest);
      setIsAuthenticated(true);
      analytics.identifyUser(auth.user);
      logger.identifyPerson({ id: auth.user.id });
    },
    [updateUser]
  );

  const clearAuth = useCallback(() => {
    jwt.clear();
    storage.removeLocalStorage(STORAGE_KEYS.user);
    setUser(undefined);
    setIsAuthenticated(false);
  }, []);

  const getUser = useCallback(() => {
    try {
      const userJSON = storage.getLocalStorage(STORAGE_KEYS.user);
      if (userJSON) {
        const user = JSON.parse(userJSON);
        setUser(user);
        analytics.identifyUser(user);
        logger.identifyPerson({
          id: user.id,
          username: `${user.firstName}${
            user.lastName ? ` ${user.lastName}` : ''
          }`,
          email: user.email ?? undefined,
        });
      }
    } catch (error) {
      logger.error(error);
    }
  }, []);

  const requestVerification = useCallback(
    async ({
      fullPhoneNumber = phoneNumber,
      alwaysSend,
    }: {
      fullPhoneNumber?: string;
      alwaysSend?: boolean;
    } = {}) => {
      try {
        const res = await authApi.requestVerification(
          fullPhoneNumber,
          alwaysSend
        );
        setPhoneNumber(fullPhoneNumber);
        setTempCode(res.tmpCode);
        return res;
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [phoneNumber]
  );

  const verifyCode = useCallback(
    async (code: string) => {
      try {
        const res = await authApi.verifyCode(phoneNumber, code);
        setVerificationCode(code);
        return res as VerifyCodeSuccessResponse;
      } catch (error) {
        throw error;
      }
    },
    [phoneNumber]
  );

  const createPassword = useCallback(
    async (password: string) => {
      try {
        const auth = await authApi.createPassword({
          code: verificationCode,
          phoneNumber,
          password,
        });
        saveAuth(auth);
      } catch (error) {
        throw error;
      }
    },
    [phoneNumber, saveAuth, verificationCode]
  );

  const changePassword = useCallback(
    async (current: string, password: string) => {
      try {
        const token = await authApi.changePassword({ current, password });
        jwt.setAuth(token);
      } catch (error) {
        throw error;
      }
    },
    []
  );

  const guestLogin = useCallback(
    async (params: GuestLoginParams) => {
      try {
        const auth = await authApi.guestLogin(params);
        setPhoneNumber(`${params.countryIsoCode}${params.phoneNumber}`);
        saveAuth(auth, true);
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [saveAuth]
  );

  const login = useCallback(
    async (fullPhoneNumber: string, password: string) => {
      try {
        const auth = await authApi.login(fullPhoneNumber, password);
        saveAuth(auth);
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [saveAuth]
  );

  const register = useCallback(
    async (data: RegisterUserParams) => {
      try {
        const auth = await authApi.register(data);
        saveAuth(auth);
        analytics.signUp();
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    [saveAuth]
  );

  const logout = useCallback(() => {
    analytics.logout();
    clearAuth();
  }, [clearAuth]);

  const initAuth = useCallback(() => {
    jwt.addOnUnauthorizedSubscriber(() => {
      logout();
    });

    getUser();
    setIsAuthenticated(jwt.hasAuth());
    setIsReady(true);
  }, [getUser, logout]);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  const getLegalEntity = useCallback(async () => {
    return isLoggedIn ? legalEntityApi.getLegalEntity() : undefined;
  }, [isLoggedIn]);

  const onLegalEntityError = useCallback((error: unknown) => {
    if (isAxiosError(error) && error.response?.status === 404) {
      return;
    }
    logger.error(error);
  }, []);

  const [legalEntityIsLoading, legalEntityError] = useRequest({
    getData: getLegalEntity,
    onSuccess: setLegalEntity,
    onError: onLegalEntityError,
    locale: intl.locale,
  });

  const legalEntityIsReady = useMemo(() => {
    if (legalEntityIsLoading) {
      return false;
    }
    if (!legalEntityError) {
      return true;
    }

    return isAxiosError(legalEntityError)
      ? legalEntityError.response?.status === 404
      : false;
  }, [legalEntityError, legalEntityIsLoading]);

  const context = useMemo(
    () => ({
      user,
      phoneNumber,
      verificationCode,
      guestLogin,
      logout,
      requestVerification,
      verifyCode,
      login,
      register,
      tempCode,
      updateUser,
      createPassword,
      changePassword,
      isLoggedIn,
      legalEntity,
      setLegalEntity,
      legalEntityIsReady,
    }),
    [
      user,
      phoneNumber,
      verificationCode,
      guestLogin,
      logout,
      requestVerification,
      verifyCode,
      login,
      register,
      tempCode,
      updateUser,
      createPassword,
      changePassword,
      isLoggedIn,
      legalEntity,
      legalEntityIsReady,
    ]
  );

  return (
    <authContext.Provider value={context}>
      {isReady ? children : null}
    </authContext.Provider>
  );
};
