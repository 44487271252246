import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '../contexts/authProvider';
import { TranslationsProvider } from '../contexts/translationsProvider';
import { WidgetRoutes } from '../routes';
import {
  ErrorBoundaryPage,
  onErrorPage,
} from 'components/pages/errorBoundaryPage';
import { messages } from 'translations';
import { ErrorBoundary } from '@bookla-app/bookla-react-components';

export const App: React.FC = () => {
  return (
    <ErrorBoundary onError={onErrorPage} errorPage={<ErrorBoundaryPage />}>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <TranslationsProvider messages={messages}>
          <AuthProvider>
            <WidgetRoutes />
          </AuthProvider>
        </TranslationsProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
