import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import {
  WidgetTemplate,
  WidgetTemplateFallback,
} from 'components/templates/widgetTemplate';
import { CheckInRoutes } from './CheckInRoutes';
import { BookingRoutes } from './BookingRoutes';
import { getLanguage, detectLocale } from '@bookla-app/bookla-react-components';
import { DownloadAppPage } from 'components/pages/downloadAppPage';
import { GiftCardActivationRoutes } from './GiftCardActivationRoutes';
import { AuthenticatedPath, RedirectBack } from './utils';
import { LeaveReviewRoutes } from '../components/organisms/leaveReview/LeaveReview';
import {CancelReservationRoutes} from "../components/organisms/cancelReservation/CancelReservation";

const ProfilePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "profile-page" */ '../components/pages/profilePage'
    )
);

const RedirectToGiftCardActivation: React.FC = () => {
  const { search } = useLocation();

  return (
    <Navigate
      to={`/${getLanguage(detectLocale())}/gift-card${search}`}
      state={{ withScroll: false }}
      replace
    />
  );
};

export const WidgetRoutes: React.FC = () => {
  return (
    <Suspense fallback={<WidgetTemplateFallback />}>
      <WidgetTemplate>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={`/${getLanguage(detectLocale())}`}
                state={{ withScroll: false }}
                replace
              />
            }
          />
          <Route path="/activate" element={<RedirectToGiftCardActivation />} />
          <Route path="/:lang/check-in/*" element={<CheckInRoutes />} />
          <Route
            path="/:lang/gift-card/*"
            element={<GiftCardActivationRoutes />}
          />
          <Route
            path="/:lang/user-profile/*"
            element={
              <AuthenticatedPath
                loggedInComponent={ProfilePage}
                loggedOutComponent={RedirectBack}
              />
            }
          />
          <Route path="/:lang/review/*" element={<LeaveReviewRoutes />} />
          <Route path="/:lang/reservation/*" element={<CancelReservationRoutes />} />
          <Route path="/:lang/*" element={<BookingRoutes />} />
          <Route path="/:lang/download-app" element={<DownloadAppPage />} />
        </Routes>
      </WidgetTemplate>
    </Suspense>
  );
};
