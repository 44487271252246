import { LegalEntity } from 'types/legalEntity';
import apiAxios from './apiAxios';

const BASE_ROUTE = '/api/user/me/legal-entity';

export const legalEntityApi = {
  getLegalEntity: (): Promise<LegalEntity> => apiAxios.get(BASE_ROUTE),
  updateLegalEntity: (data: LegalEntity): Promise<LegalEntity> =>
    apiAxios.put(BASE_ROUTE, data),
  deleteLegalEntity: (): Promise<void> => apiAxios.delete(BASE_ROUTE),
};
