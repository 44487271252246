import en from './en.json';
import lv from './lv.json';
import ru from './ru.json';
import lt from './lt.json';
import es from './es.json';
import it from './it.json';
import { setLocale } from 'yup';
import { SupportedLanguages } from '@bookla-app/bookla-react-components';

export type Translations = Record<SupportedLanguages, Record<string, string>>;

export const messages: Translations = {
  en,
  lv,
  ru,
  lt,
  it,
  es,
};

interface YupErrorProps {
  value: unknown;
  originalValue: unknown;
  label?: string;
  path: string;
}

const sharedErrors = {
  default: 'error_field_invalid',
  required: 'error_field_required',
};

const max = ({ max }: YupErrorProps & { max: number }) => {
  return { key: 'error_field_max', values: { max } };
};

const min = ({ min }: YupErrorProps & { min: number }) => {
  return { key: 'error_field_min', values: { min } };
};

const length = ({ length }: YupErrorProps & { length: number }) => {
  return { key: 'error_field_length', values: { length } };
};

setLocale({
  mixed: sharedErrors,
  string: { ...sharedErrors, max, min, length, email: 'error_field_email' },
  number: { ...sharedErrors, max, min },
  boolean: sharedErrors,
  object: sharedErrors,
});
