import { companyApi } from 'api/company';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from 'contexts/AppStateProvider';
import { CompanyResponse } from 'types/company';
import { RouteParams } from './routes';
import { useRequestToastError } from './useRequestToastError';
import { useIntl } from 'react-intl';
import { parseServicesIntoGroups } from './parseAndGroupServices';
import { ItemGroup } from 'components/templates/serviceListTemplate';
import { ProductItem } from 'components/molecules/productItem';
import { ServiceItem } from 'components/molecules/serviceItem';
import { useRequest } from '@bookla-app/bookla-react-components';
import { GiftCardItem } from 'components/molecules/giftCardItem';
import {
  widgetOptions,
} from 'utils/widgetOptions';
import {parseProducts} from "./parseProducts";

export const useLoadCompanyDetails = () => {
  const intl = useIntl();
  const { companyId } = useParams<RouteParams>();
  const { setCompanyDetails } = useAppState();
  const [groupedServicesList, setGroupedServicesList] = useState<ItemGroup[]>();
  const [productsList, setProductsList] = useState<ItemGroup[]>();
  const [giftCardsList, setGiftCardsList] = useState<ItemGroup[]>();

  const otherServicesTabTitle = useMemo(
    () => intl.formatMessage({ id: 'services_page_tab_other_services' }),
    [intl]
  );

  const getCompany = useCallback(async () => {
    return companyId ? companyApi.getCompanyDetails(companyId) : undefined;
  }, [companyId]);

  const setCompany = useCallback(
    (res?: CompanyResponse) => {
      if (!res) {
        return;
      }
      setCompanyDetails(res.company);

      const { groupedServices } = parseServicesIntoGroups(
        res,
        otherServicesTabTitle,
        widgetOptions.visibleServiceGroupIds,
      );

      const {products} = parseProducts(res, widgetOptions.visibleProductIds?.filter(Boolean));

      setGroupedServicesList(
        groupedServices.map(({ services, groupTitle }) => ({
          groupTitle,
          items: services.map((service) => (
            <ServiceItem key={service.title} {...service} />
          )),
        }))
      );

      setProductsList(
        products
          ? [
              {
                items: products.map((product) => (
                  <ProductItem key={product.title} {...product} />
                )),
              },
            ]
          : []
      );

      setGiftCardsList(
        res.giftCards
          ? [
              {
                items: res.giftCards.map((giftCard) => (
                  <GiftCardItem key={giftCard.id} {...giftCard} />
                )),
              },
            ]
          : []
      );
    },
    [otherServicesTabTitle, setCompanyDetails]
  );

  const [isLoading, error] = useRequest({
    getData: getCompany,
    onSuccess: setCompany,
    onError: useRequestToastError(),
    locale: intl.locale,
  });

  const isReady =
    !isLoading && !error && !!groupedServicesList && !!productsList;

  return { isReady, groupedServicesList, productsList, giftCardsList };
};
