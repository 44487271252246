import { IconProps, Path, Svg } from '@bookla-app/bookla-react-components';

export const TrashIcon: React.FC<IconProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <Path
      d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9ZM15.5 4L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H15.5Z"
      {...props}
    />
    <Path
      d="M9.75001 11C9.75001 10.7239 9.97387 10.5 10.25 10.5H10.75C11.0262 10.5 11.25 10.7239 11.25 11V17C11.25 17.2761 11.0262 17.5 10.75 17.5H10.25C9.97387 17.5 9.75001 17.2761 9.75001 17V11Z"
      {...props}
    />
    <Path
      d="M12.75 11C12.75 10.7239 12.9739 10.5 13.25 10.5H13.75C14.0262 10.5 14.25 10.7239 14.25 11V17C14.25 17.2761 14.0262 17.5 13.75 17.5H13.25C12.9739 17.5 12.75 17.2761 12.75 17V11Z"
      {...props}
    />
  </Svg>
);
