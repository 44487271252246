import { Calendar } from './calendar';
import { GiftCard } from './giftCard';
import { BaseProduct } from './product';
import { BaseService } from './service';

export interface CompanyService extends BaseService {
  thumbnail: string;
}

export interface CompanyServiceGroup {
  id: string;
  priority: number;
  title: string;
}

export enum PaymentOptionsType {
  CashCard = 'cashCard',
  Cash = 'cash',
  Card = 'card',
  InApp = 'inApp',
  Bank = 'bank',
}

export enum PaymentProvider {
  Montonio = 'montonio',
  Stripe = 'stripe',
}

export interface Company {
  id: string;
  title: string;
  address: string;
  paymentOptionsType: PaymentOptionsType;
  paymentProviders: PaymentProvider[];
  latitude: string;
  longitude: string;
  country: string;
  promoCodesEnabled: boolean;
  cancellationRules: {
    hoursBeforeFullRefund: number;
    hoursBeforeHalfRefund: number;
  };
  timeZone: string;
  contacts: { type: 'email' | 'website' | 'phone'; value: string }[];
  currency: string;
}

export interface CompanyResponse {
  services: CompanyService[];
  serviceGroups: CompanyServiceGroup[];
  company: Company;
  calendars: Calendar[];
  products?: BaseProduct[];
  giftCards?: GiftCard[];
}

export interface TicketBundle {
  title: string;
  description: string;
}
