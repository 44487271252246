import { FormattedMessage } from 'react-intl';
import { LadyWithPhoneIcon } from '@bookla-app/bookla-react-components';
import { AppleStoreButton } from 'components/molecules/appleStoreButton';
import { GooglePlayButton } from 'components/molecules/googlePlayButton';
import { Modal, ModalProps } from 'components/molecules/modal';
import classNames from 'classnames';

interface DownloadAppBodyProps {
  descriptionId: string;
  large?: boolean;
}

export const DownloadAppBody: React.FC<DownloadAppBodyProps> = ({
  descriptionId,
  large = false,
}) => (
  <div className="flex flex-col items-center">
    <div className={classNames(large && 'max-w-sm w-full')}>
      <LadyWithPhoneIcon fullSize={large} />
    </div>
    <h3
      className={classNames(
        large ? 'text-xl sm:text-2xl font-medium mb-4' : 'mb-1 font-medium'
      )}
    >
      <FormattedMessage id="profile_page_download_app_modal_title" />
    </h3>
    <p
      className={classNames('text-xs text-primary mb-5', large && 'sm:text-sm')}
    >
      <FormattedMessage id={descriptionId} />
    </p>
    <div
      className={classNames('flex flex-wrap justify-center', large && 'mt-8')}
    >
      <AppleStoreButton />
      <GooglePlayButton />
    </div>
  </div>
);

export const DownloadAppModal: React.FC<Omit<ModalProps, 'body'>> = (props) => {
  return (
    <Modal
      body={
        <DownloadAppBody descriptionId="profile_page_download_app_modal_description" />
      }
      {...props}
    />
  );
};
