import { ExternalLink } from 'components/atoms/ExternalLink';
import { useLocale } from 'contexts/translationsProvider';
import { useIntl } from 'react-intl';

interface GooglePlayButtonProps {}

export const GooglePlayButton: React.FC<GooglePlayButtonProps> = () => {
  const intl = useIntl();
  const { language } = useLocale();

  return (
    <ExternalLink
      href="https://play.google.com/store/apps/details?id=lv.chi.spendo.client&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      className="flex-shrink-0 block h-10 sm:h-16"
    >
      <img
        alt={intl.formatMessage({
          id: 'profile_page_google_play_aria_label',
        })}
        src={`https://play.google.com/intl/en_us/badges/static/images/badges/${language}_badge_web_generic.png`}
        className="h-full"
      />
    </ExternalLink>
  );
};
