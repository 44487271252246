import { useEffect } from 'react';
import { Button, LadySearchingIcon } from '@bookla-app/bookla-react-components';
import { Header } from 'components/molecules/header';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { analytics } from 'utils/analytics';

interface NotFoundPageProps {}

export const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    analytics.pageLoaded('pageNotFound');
  }, []);

  return (
    <div className="page">
      <Header
        title={intl.formatMessage({ id: 'not_found_page_title' })}
        goBack={goBack}
      />
      <main className="flex flex-col items-center flex-grow px-5 pb-5 md:px-10">
        <div className="max-w-sm w-full my-9">
          <LadySearchingIcon fullSize />
        </div>
        <h2 className="text-xl sm:text-2xl font-medium my-4">
          <FormattedMessage id="not_found_page_title" />
        </h2>
        <p className="text-xs sm:text-sm mb-14">
          <FormattedMessage id="not_found_page_description" />
        </p>
        <Button onClick={goBack} size="sm">
          <FormattedMessage id="button_go_back" />
        </Button>
      </main>
    </div>
  );
};
