import { Logger } from '@bookla-app/bookla-react-components';
import { hasCookieConsent } from 'components/organisms/cookieBanner/utils';
import Rollbar from 'rollbar';

const rollbarConfig: Rollbar.Configuration = {
  enabled: process.env.NODE_ENV === 'production',
  accessToken: process.env.REACT_APP_RUNTIME_ERROR_MONITORING_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_BOOKLA_ENV,
  },
  codeVersion: process.env.REACT_APP_VERSION,
};

export const logger = new Logger(
  rollbarConfig,
  process.env.NODE_ENV === 'production',
  () => hasCookieConsent('isStatisticsSelected')
);
