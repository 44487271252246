import { IntlShape } from 'react-intl';
import { PromoCode, ParsedPromoCode, DiscountType } from 'types/service';
import { formatCurrency } from '@bookla-app/bookla-react-components';

export const parsePromoCode = (
  promoCode: PromoCode,
  intl: IntlShape
): ParsedPromoCode => ({
  ...promoCode,
  discountText:
    promoCode.discountType === DiscountType.Percent
      ? `-${promoCode.discount}%`
      : `-${formatCurrency({
          value: promoCode.discount,
          currency: promoCode.currency,
          intl,
        })}`,
});
