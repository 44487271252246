import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import { isInIframe } from '../utils/isInIframe';
import {
  isLanguageSupported,
  canUseCookies,
} from '@bookla-app/bookla-react-components';
import { getPath, ROUTES } from '../utils/routes';
import { AuthenticatedPath } from './utils';
import { ItemCategories } from 'types/common';
import { widgetOptions } from 'utils/widgetOptions';
import { useLoadCompanyDetails } from 'utils/useLoadCompanyDetails';
import { NotFoundPage } from 'components/pages/notFoundPage';
import { DefaultHomepageTab } from 'utils/url';

const HomePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "services-page" */ '../components/pages/homePage'
    )
);
const ServicePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "booking-page" */ '../components/pages/servicePage'
    )
);
const ServiceCustomFormPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "service-custom-form-page" */ '../components/pages/serviceCustomFormPage'
    )
);
const ServiceSummaryPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "service-summary-page" */ '../components/pages/serviceSummaryPage'
    )
);
const CartCheckoutPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "cart-checkout-page" */ '../components/pages/cartCheckoutPage'
    )
);
const SubscriptionPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "subscription-page" */ '../components/pages/subscriptionPage'
    )
);
const SubscriptionCustomFormPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "subscription-custom-form-page" */ '../components/pages/subscriptionCustomFormPage'
    )
);
const SubscriptionSummaryPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "subscription-summary-page" */ '../components/pages/subscriptionSummaryPage'
    )
);
const SuccessfulPaymentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "successful-payment-page" */ '../components/pages/successfulPaymentPage'
    )
);
const GiftCardPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "gift-card-page" */ '../components/pages/giftCardPage'
    )
);
const GiftCardDesignPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "gift-card-design-page" */ '../components/pages/giftCardDesignPage'
    )
);
const GiftCardSummaryPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "gift-card-summary-page" */ '../components/pages/giftCardSummaryPage'
    )
);

const RedirectHome: React.FC = () => {
  const defaultTabToRouteMap: Record<DefaultHomepageTab, string> = {
    [DefaultHomepageTab.Services]: ROUTES.SERVICES,
    [DefaultHomepageTab.Products]: ROUTES.PRODUCTS,
    [DefaultHomepageTab.GiftCards]: ROUTES.GIFT_CARDS,
  };

  return (
    <Navigate
      to={{
        pathname: widgetOptions.companyId
          ? getPath(
              widgetOptions.defaultHomepageTab
                ? defaultTabToRouteMap[widgetOptions.defaultHomepageTab]
                : ROUTES.SERVICES,
              { companyId: widgetOptions.companyId }
            )
          : getPath(ROUTES.NOT_FOUND),
      }}
      replace
      state={{ withScroll: false }}
    />
  );
};

const WidgetCompanyRoutes: React.FC = () => {
  const { isReady, groupedServicesList, productsList, giftCardsList } =
    useLoadCompanyDetails();

  return (
    <Routes>
      <Route
        path="/services"
        element={
          <HomePage
            category={ItemCategories.Services}
            isReady={isReady}
            groupedServicesList={groupedServicesList}
            productsList={productsList}
            giftCardsList={giftCardsList}
          />
        }
      />
      <Route
        path="/products"
        element={
          <HomePage
            category={ItemCategories.Products}
            isReady={isReady}
            groupedServicesList={groupedServicesList}
            productsList={productsList}
            giftCardsList={giftCardsList}
          />
        }
      />
      <Route
        path="/gift-cards"
        element={
          <HomePage
            category={ItemCategories.GiftCards}
            isReady={isReady}
            groupedServicesList={groupedServicesList}
            productsList={productsList}
            giftCardsList={giftCardsList}
          />
        }
      />
      <Route path="/services/:serviceId" element={<ServicePage />} />
      <Route
        path="/services/:serviceId/form"
        element={<ServiceCustomFormPage />}
      />
      <Route path="/products/:productId" element={<SubscriptionPage />} />
      <Route
        path="/products/:productId/form"
        element={<SubscriptionCustomFormPage />}
      />
      <Route path="/gift-cards/:giftCardId" element={<GiftCardPage />} />
      <Route
        path="/gift-cards/:giftCardId/design"
        element={
          <AuthenticatedPath
            loggedInComponent={GiftCardDesignPage}
            loggedOutComponent={RedirectHome}
          />
        }
      />
      <Route
        path="/services/:serviceId/successful-payment"
        element={<SuccessfulPaymentPage />}
      />
      <Route
        path="/products/:productId/successful-payment"
        element={<SuccessfulPaymentPage />}
      />
      <Route
        path="/gift-cards/:giftCardId/successful-payment"
        element={<SuccessfulPaymentPage />}
      />
      <Route
        path="/cart/successful-payment"
        element={<SuccessfulPaymentPage />}
      />
      <Route
        path="/services/:serviceId/summary"
        element={
          <AuthenticatedPath
            loggedInComponent={ServiceSummaryPage}
            loggedOutComponent={RedirectHome}
          />
        }
      />
      <Route
        path="/cart"
        element={
          <AuthenticatedPath
            loggedInComponent={CartCheckoutPage}
            loggedOutComponent={RedirectHome}
          />
        }
      />
      <Route
        path="/products/:productId/summary"
        element={
          <AuthenticatedPath
            loggedInComponent={SubscriptionSummaryPage}
            loggedOutComponent={RedirectHome}
          />
        }
      />
      <Route
        path="/gift-cards/:giftCardId/summary"
        element={
          <AuthenticatedPath
            loggedInComponent={GiftCardSummaryPage}
            loggedOutComponent={RedirectHome}
          />
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export const BookingRoutes: React.FC = () => {
  const { lang } = useParams<{ lang?: string }>();

  if (!isInIframe() && !canUseCookies()) {
    return (
      <Navigate
        to={getPath(ROUTES.DOWNLOAD_APP)}
        state={{ withScroll: false }}
        replace
      />
    );
  }

  if (!lang || !isLanguageSupported(lang)) {
    return <RedirectHome />;
  }

  return (
    <Routes>
      <Route path="/" element={<RedirectHome />} />
      <Route path="/company" element={<RedirectHome />} />
      <Route path="/company/:companyId/*" element={<WidgetCompanyRoutes />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
