import { useEffect, useRef, useState } from 'react';

export const usePortal = (parentId: string) => {
  const [isReady, setIsReady] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const setRef = (): HTMLDivElement => {
    if (!ref.current) {
      (ref.current as any) = document.createElement('div');
    }
    return ref.current as HTMLDivElement;
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const parentEl = document.getElementById(parentId);

    parentEl?.appendChild(ref.current);
    setIsReady(true);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current?.remove();
    };
  }, [parentId]);

  return { ref: setRef(), isReady };
};
