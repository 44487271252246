import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// to account for business page headers/banners
export const DEFAULT_OFFSET = -100;

export const scrollToTop = () => {
  window.parentIFrame?.scrollToOffset(0, DEFAULT_OFFSET);
};

export const scrollTo = (offsetTop: number) => {
  window.parentIFrame?.scrollToOffset(0, offsetTop);
};

// Sometimes scroll height and client height differ by a very small margin.
const SCROLL_HEIGHT_THRESHOLD = 5;

export const isElementScrollable = (
  el: HTMLElement | null,
  thresholdPx = SCROLL_HEIGHT_THRESHOLD
) => {
  return el ? Math.abs(el.scrollHeight - el.clientHeight) > thresholdPx : false;
};

export const PageScroller: React.FC = () => {
  const { pathname, state } = useLocation();
  const [previousPath, setPreviousPath] = useState(pathname);

  const { withScroll } = state || { withScroll: true };

  useEffect(() => {
    if (previousPath !== pathname && withScroll !== false) {
      scrollToTop();
      setPreviousPath(pathname);
    }
  }, [previousPath, pathname, withScroll]);

  return null;
};
