import { getUrlParams, UrlParams } from './url';

export const ROUTES = {
  SERVICES: '/:lang/company/:companyId/services',
  SERVICE: '/:lang/company/:companyId/services/:serviceId',
  SERVICE_CUSTOM_FORM: '/:lang/company/:companyId/services/:serviceId/form',
  SERVICE_SUMMARY: '/:lang/company/:companyId/services/:serviceId/summary',
  SERVICE_SUCCESSFUL_PAYMENT:
    '/:lang/company/:companyId/services/:serviceId/successful-payment',
  PRODUCTS: '/:lang/company/:companyId/products',
  PRODUCT: '/:lang/company/:companyId/products/:productId',
  PRODUCT_CUSTOM_FORM: '/:lang/company/:companyId/products/:productId/form',
  PRODUCT_SUMMARY: '/:lang/company/:companyId/products/:productId/summary',
  PRODUCT_SUCCESSFUL_PAYMENT:
    '/:lang/company/:companyId/products/:productId/successful-payment',
  GIFT_CARDS: '/:lang/company/:companyId/gift-cards',
  GIFT_CARD: '/:lang/company/:companyId/gift-cards/:giftCardId',
  GIFT_CARD_DESIGN: '/:lang/company/:companyId/gift-cards/:giftCardId/design',
  GIFT_CARD_SUMMARY: '/:lang/company/:companyId/gift-cards/:giftCardId/summary',
  GIFT_CARD_SUCCESSFUL_PAYMENT:
    '/:lang/company/:companyId/gift-cards/:giftCardId/successful-payment',
  GIFT_CARD_ACTIVATE: '/:lang/gift-card',
  PROFILE: '/:lang/user-profile',
  MY_GIFT_CARDS: '/:lang/user-profile/my-gift-cards',
  MY_GIFT_CARDS_LIST: '/:lang/user-profile/my-gift-cards/list',
  MY_GIFT_CARDS_DETAILS: '/:lang/user-profile/my-gift-cards/:giftCardId',
  MY_RESERVATIONS: '/:lang/user-profile/my-reservations/list',
  MY_RESERVATION: '/:lang/user-profile/my-reservations/:reservationId',
  MY_RESERVATION_QR_CODE:
    '/:lang/user-profile/my-reservations/:reservationId/qr-code',
  MY_RESERVATION_REVIEW:
    '/:lang/user-profile/my-reservations/:reservationId/review',
  MY_RESERVATION_CANCEL:
    '/:lang/user-profile/my-reservations/:reservationId/cancel',
  MY_RESERVATION_CANCEL_SUCCESSFUL:
    '/:lang/user-profile/my-reservations/:reservationId/cancel-successful',
  MY_RESERVATION_MOVE:
    '/:lang/user-profile/my-reservations/:reservationId/move',
  CHECK_IN: '/:lang/check-in/venues/:venueId',
  NOT_FOUND: '/:lang/not-found',
  DOWNLOAD_APP: '/:lang/download-app',
  CART: '/:lang/company/:companyId/cart',
  CART_SUCCESSFUL_PAYMENT: '/:lang/company/:companyId/cart/successful-payment',
};

export type RouteParams = Pick<
  UrlParams,
  | 'lang'
  | 'companyId'
  | 'serviceId'
  | 'productId'
  | 'giftCardId'
  | 'reservationId'
>;

type BaseParams = Pick<RouteParams, 'lang' | 'companyId'>;

export type ProductRouteParams = BaseParams &
  Required<Pick<RouteParams, 'productId'>>;

export type ServiceRouteParams = BaseParams &
  Required<Pick<RouteParams, 'serviceId'>>;

export type GiftCardRouteParams = BaseParams &
  Required<Pick<RouteParams, 'giftCardId'>>;

export type MyReservationRouteParams = Pick<RouteParams, 'reservationId'>;

export const getPath = (
  route: string,
  params?: Partial<RouteParams>,
  href?: string
) => {
  const { theme, ...rest } = getUrlParams(href || window.location.href);
  const routeParams = { ...rest, ...params };

  let newRoute = route;
  Object.keys(routeParams).forEach((key) => {
    const value = routeParams[key as keyof RouteParams];
    if (value) {
      newRoute = newRoute.replace(`:${key}`, value);
    }
  });

  return newRoute;
};
