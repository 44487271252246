import { PaymentProvider } from './company';
import { ReservationStatus } from './reservation';
import { Service } from './service';

export enum ProductType {
  GiftCard = 'giftCard',
  Subscription = 'subscription',
  Code = 'code',
}

export interface BaseProduct {
  currency: string;
  deepLink: string;
  description: string;
  enabled: boolean;
  id: string;
  price: number;
  title: string;
  subtitle?: string;
  type: ProductType;
  customTermsId?: string;
  customFormId?: string;
  timeZone: string;
  isRecurring: boolean;
  dateSelectionDisabled: boolean;
}

export enum ProductSubscriptionPeriod {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Custom = 'custom',
}

export interface Product extends BaseProduct {
  activeDays: number;
  companyTitle: string;
  linkedServices: (Omit<Service, 'type'> & { thumbnail: string })[];
  reservationLimitDays: number;
  visitCountLimit: number;
  periodicity?: ProductSubscriptionPeriod;
  periodicityDays?: number;
  productSubscriptionID?: string;
  status?: ReservationStatus;
  paymentProviders?: PaymentProvider[];
}
