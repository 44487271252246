import {
  ReservationDetails,
  ReservationParams,
  ReservationStatus,
} from 'types/reservation';
import { dateHasStarted } from './date';
import { AppStateContext } from 'contexts/AppStateProvider';
import { ScheduleType } from 'types/schedule';
import { parseTicketsWithSpots } from './parseTickets';
import { PaymentType } from 'types/payments';
import { LegalEntityFormikValues } from 'components/templates/summaryTemplate/types';
import { PaymentModalFormikValues } from 'components/organisms/paymentModal/types';
import { PromoCodeFormikValues } from 'components/organisms/promoCodeSelector/PromoCodeSelector';
import { ServiceSummaryDetailsCardFormikValues } from 'components/organisms/serviceSummaryDetailsCard/ServiceSummaryDetailsCard';
import { BookingOptionsState } from './useBookingOptions';
import { widgetOptions } from 'utils/widgetOptions';

export const isReservationNotCancellable = (
  reservation: ReservationDetails
) => {
  const refundDeadline = new Date(reservation.startDate);
  refundDeadline.setHours(
    refundDeadline.getHours() -
      reservation.company.cancellationRules.hoursBeforeHalfRefund
  );

  return (
    reservation.status === ReservationStatus.Paid &&
    dateHasStarted(refundDeadline.toISOString())
  );
};

export const canShowEditReservationButton = (
  reservation: ReservationDetails
) => {
  return [ReservationStatus.Confirmed, ReservationStatus.Paid].includes(
    reservation.status
  );
};

export const isReservationEditable = (reservation: ReservationDetails) => {
  const editableDeadline = new Date(reservation.startDate);
  editableDeadline.setHours(
    editableDeadline.getHours() -
      reservation.company.cancellationRules.hoursBeforeFullRefund
  );

  return (
    canShowEditReservationButton(reservation) &&
    !dateHasStarted(editableDeadline.toISOString())
  );
};

export type UnparsedReservationDataParams = Pick<
  AppStateContext,
  'calendarOfTimeslot' | 'selectedSpots' | 'service' | 'serviceCustomForm'
> & { bookingOptions: BookingOptionsState } & Partial<
    LegalEntityFormikValues &
      Pick<PaymentModalFormikValues, 'selectedPaymentMethod'> &
      PromoCodeFormikValues &
      ServiceSummaryDetailsCardFormikValues
  >;

export const getReservationData = ({
  bookingOptions: { selectedDuration, selectedTimeslot, selectedTickets },
  calendarOfTimeslot,
  selectedSpots,
  service,
  serviceCustomForm,
  checkoutAsLegalEntity,
  checkoutAsLegalEntityComment,
  selectedPaymentMethod,
  promoCode,
  note,
}: UnparsedReservationDataParams): ReservationParams => {
  if (!calendarOfTimeslot || !selectedTimeslot || !service) {
    throw new Error('Cannot make reservation without a selected time slot');
  }

  if (widgetOptions.commentToAppend && note) {
    note += "\n"+widgetOptions.commentToAppend;
  } else if (widgetOptions.commentToAppend) {
    note = widgetOptions.commentToAppend;
  }

  return {
    calendarId: calendarOfTimeslot.id,
    comment: note,
    duration: selectedDuration,
    promoCodeId: promoCode?.id,
    serviceId: service.id,
    slotId:
      selectedTimeslot.type === ScheduleType.Slot
        ? selectedTimeslot.slotId
        : undefined,
    spots: selectedSpots,
    startDate: new Date(selectedTimeslot.startTime).toISOString(),
    tickets: parseTicketsWithSpots(selectedTimeslot, selectedTickets)?.map(
      ({ id, spots }) => ({ slotTicketId: id, spots })
    ),
    type: calendarOfTimeslot.type,
    isBankTransfer: selectedPaymentMethod?.type === PaymentType.Bank,
    checkoutAsLegalEntity: checkoutAsLegalEntity ?? false,
    checkoutAsLegalEntityComment,
    customForm: serviceCustomForm,
  };
};
