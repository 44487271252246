import { StripeError } from '@stripe/stripe-js';
import axios, { AxiosError } from 'axios';
import { IntlShape } from 'react-intl';

export const isAxiosError = (
  error: unknown
): error is AxiosError<{ message: string }> => {
  return axios.isAxiosError(error);
};

export const isStripeError = (error: unknown): error is StripeError => {
  return typeof error === 'object' && !!error && !!(error as StripeError).type;
};

export const isError = (
  error: unknown
): error is AxiosError | Error | StripeError => {
  return isAxiosError(error) || error instanceof Error || isStripeError(error);
};

export const getErrorMessage = (error: unknown, intl: IntlShape) => {
  const defaultMessage = intl.formatMessage({ id: 'error_generic' });

  if (isAxiosError(error)) {
    return error.response?.status === 401
      ? intl.formatMessage({ id: 'error_expired_session' })
      : error.response?.data?.message || defaultMessage;
  }
  if (error instanceof Error) {
    return error.message || defaultMessage;
  }
  return defaultMessage;
};
