import classNames from 'classnames';
import { Form } from 'formik';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';
import { usePortal } from '../../../utils/usePortal';
import {
  IconButton,
  CrossIcon,
  ArrowLeftIcon,
} from '@bookla-app/bookla-react-components';
import { UILoader } from '../uiLoader';

export interface ModalProps {
  title?: string | JSX.Element;
  withCloseBtn?: boolean;
  closeModal?: () => void;
  body: JSX.Element;
  footer?: JSX.Element;
  /**
   *  Form does not work outside of React portal so it needs to be set internally when isForm is true.
   */
  isForm?: boolean;
  /**
   * Supports max 4 steps
   */
  withSteps?: boolean;
  currentStep?: number;
  disableAnimationForSteps?: number[];
  withMinHeight?: boolean;
  withMobileHeight?: boolean;
  isBlocking?: boolean;
  goBack?: () => void;
  withFooterShadow?: boolean;
}

// cannot use partial string literals of tailwind classes, because tailwind cannot detect those classes and will purge them
const stepTransitionMap: Record<number, string> = {
  0: 'translate-x-step-0',
  1: 'translate-x-step-1',
  2: 'translate-x-step-2',
  3: 'translate-x-step-3',
  4: 'translate-x-step-4',
  5: 'translate-x-step-5',
  6: 'translate-x-step-6',
};

// TODO: accessibility and add animations
export const Modal: React.FC<ModalProps> = ({
  withCloseBtn = true,
  body,
  closeModal,
  title,
  footer,
  currentStep,
  isForm = false,
  withSteps = false,
  disableAnimationForSteps = [],
  withMinHeight = true,
  withMobileHeight = true,
  isBlocking = false,
  goBack,
  withFooterShadow = false,
}) => {
  const { ref, isReady } = usePortal('modal-container');
  const bodyRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    if (currentStep !== undefined) {
      bodyRef.current?.parentElement?.scrollTo(0, 0);
    }
  }, [currentStep]);

  const component = (
    <div className="bg-gray bg-opacity-40 flex place-content-center absolute inset-0 px-4 z-40">
      <div
        className={classNames(
          'flex flex-col m-auto max-w-sm w-full bg-primary border border-divider rounded-2xl overflow-hidden',
          withMinHeight && withMobileHeight && 'h-modal',
          withMinHeight && !withMobileHeight && 'sm:h-modal'
        )}
      >
        {(withCloseBtn || !!title || !!goBack) && (
          <div className="flex justify-between items-center px-5 pt-7 pb-5 border-b border-divider">
            {!!goBack && (
              <IconButton
                onClick={goBack}
                ariaLabel={intl.formatMessage({
                  id: 'header_back_to_previous_page_aria_label',
                })}
                icon={ArrowLeftIcon}
                size="sm"
                padding="p-0"
              />
            )}
            <h2
              className={classNames(
                'text-xl font-medium',
                !!goBack && 'ml-4 mr-auto'
              )}
            >
              {title}
            </h2>
            {withCloseBtn && !!closeModal && (
              <IconButton
                onClick={closeModal}
                ariaLabel={intl.formatMessage({
                  id: 'modal_close_aria_label',
                })}
                icon={CrossIcon}
                size="sm"
              />
            )}
          </div>
        )}
        <UILoader
          isBlocking={isBlocking}
          className="px-5 overflow-y-auto overflow-x-hidden flex-1 min-h-24"
        >
          <div
            className={classNames(
              'pt-7',
              withSteps && 'flex justify-start h-full',
              withSteps &&
                currentStep !== undefined &&
                !disableAnimationForSteps.includes(currentStep) &&
                `transform transition-transform ${stepTransitionMap[currentStep]}`
            )}
            ref={bodyRef}
          >
            {body}
          </div>
        </UILoader>
        {footer ? (
          <div
            className={classNames(
              'flex justify-end p-5',
              withFooterShadow && 'shadow-top'
            )}
          >
            {footer}
          </div>
        ) : null}
      </div>
    </div>
  );

  if (!isReady) {
    return null;
  }

  return ReactDOM.createPortal(
    <FocusLock>{isForm ? <Form>{component}</Form> : component}</FocusLock>,
    ref
  );
};
