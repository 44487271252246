import { useEffect } from 'react';
import { DownloadAppBody } from 'components/organisms/downloadAppModal';
import { analytics } from '../../../utils/analytics';
import { LogoLink } from 'components/molecules/logoLink';
import { useLocale } from 'contexts/translationsProvider';

export const DownloadAppPage: React.FC = () => {
  const { language } = useLocale();

  useEffect(() => {
    analytics.pageLoaded('pageDownloadApp');
  }, []);

  return (
    <div className="page">
      <div className="ml-auto my-3.5 mr-5 sm:my-7 md:mr-10">
        <LogoLink language={language} />
      </div>
      <main className="flex flex-col p-5 md:p-10 justify-center">
        <DownloadAppBody
          large
          descriptionId="unsupported_browser_description"
        />
      </main>
    </div>
  );
};
