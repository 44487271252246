import { ErrorMessage } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

type ValidationErrorObj = {
  key: string;
  values: Record<string, React.ReactNode>;
};

type SimpleValidationError = string | ValidationErrorObj;

type ValidationError = SimpleValidationError | SimpleValidationError[];

interface FormErrorProps {
  name: string;
  error?: ValidationError;
  className?: string;
}

const parseSimpleError = (formError?: SimpleValidationError) => {
  return typeof formError === 'string' || !formError
    ? { id: formError }
    : { ...formError, id: formError.key };
};

const parseFormError = (formError: ValidationError) => {
  if (Array.isArray(formError)) {
    const firstError = formError.find((err) => !!err);

    return parseSimpleError(firstError);
  }
  return parseSimpleError(formError);
};

const InnerMessage: React.FC<{ error: ValidationError; name: string }> = ({
  error,
  name,
}) => {
  const intl = useIntl();
  const parsedError = parseFormError(error);
  if (!parsedError.id) {
    return null;
  }
  const stringExists = !!intl.messages[parsedError.id];
  return (
    <span
      className="text-status-error text-xs"
      aria-live="polite"
      aria-describedby={name}
    >
      {stringExists ? <FormattedMessage {...parsedError} /> : parsedError.id}
    </span>
  );
};

export const FormError: React.FC<FormErrorProps> = ({
  name,
  error,
  className,
}) => {
  return (
    <div className={classNames(className, 'min-h-6 mb-1')}>
      {error ? (
        <InnerMessage name={name} error={error} />
      ) : (
        <ErrorMessage name={name}>
          {(formError) => <InnerMessage name={name} error={formError} />}
        </ErrorMessage>
      )}
    </div>
  );
};
