import { useAppState } from 'contexts/AppStateProvider';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { getErrorMessage } from './getRequestError';
import { logger } from './logger';

export const useRequestToastError = () => {
  const intl = useIntl();
  const { addToastMessage } = useAppState();

  return useCallback(
    (error: unknown) => {
      addToastMessage({
        reloadPage: true,
        message: getErrorMessage(error, intl),
      });
      logger.error(error);
    },
    // should not take intl into account, as it will cause double requests
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToastMessage]
  );
};
