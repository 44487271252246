import { useLocale } from 'contexts/translationsProvider';
import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import {
  getThemeColor,
  theme,
  languageOptions,
  LanguageOption,
} from '@bookla-app/bookla-react-components';
import {userApi} from "../../../api/user";
import {useAuth} from "../../../contexts/authProvider";

interface LanguageSelectProps {
  className?: string;
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
  className,
}) => {
  const { locale, setLocale } = useLocale();
  const { isLoggedIn } = useAuth();
  const selectedOption = useMemo(
    () =>
      languageOptions.find(({ value }) => value === locale) ||
      languageOptions[0],
    [locale]
  );
  const onChange = useCallback(
    async (option: LanguageOption | null) => {
      if (option) {
        setLocale(option.value);
        if (isLoggedIn) {
          try {
            await userApi.changeLanguage();
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    [setLocale, isLoggedIn]
  );

  return (
    <Select
      value={selectedOption}
      options={languageOptions.filter((opt) => opt !== selectedOption)}
      onChange={onChange}
      classNamePrefix="language-select"
      isClearable={false}
      isSearchable={false}
      menuPosition="fixed"
      openMenuOnFocus
      className={className}
      styles={{
        control: (base, props) => ({
          ...base,
          border: 'none',
          transition: 'none',
          outline: 'none',
          boxShadow: props.isFocused
            ? `inset 0px 0px 0px 2px ${getThemeColor(
                theme.textColor.accent.DEFAULT
              )}`
            : 'none',
          cursor: 'pointer',
          paddingRight: '4px',
          backgroundColor: 'transparent',
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: (base) => ({
          ...base,
          color: `${getThemeColor(theme.textColor.secondary)} !important`,
          padding: 0,
        }),
        option: (base, props) => ({
          ...base,
          backgroundColor: getThemeColor(
            props.isSelected
              ? theme.colors.accent.DEFAULT
              : props.isFocused
              ? theme.colors.accent.light
              : theme.backgroundColor.primary
          ),
          color: getThemeColor(
            props.isSelected
              ? theme.colors.accent.contrast
              : theme.textColor.primary
          ),
          '&:hover, &:active': {
            backgroundColor: props.isSelected
              ? theme.colors.accent.DEFAULT
              : theme.colors.accent.light,
          },
          padding: '8px',
          whiteSpace: 'nowrap',
          fontSize: '14px',
          cursor: 'pointer',
        }),
        valueContainer: (base) => ({ ...base, flexWrap: 'nowrap' }),
        singleValue: (base) => ({
          ...base,
          position: 'relative',
          top: 'none',
          margin: 0,
          maxWidth: 'auto',
          transform: 'none',
          fontSize: '14px',
          lineHeight: '1',
          overflow: 'visible',
          color: getThemeColor(theme.textColor.primary),
        }),
        menuList: (base) => ({ ...base, padding: 0 }),
        menu: (base) => ({ ...base, overflow: 'hidden', margin: 0 }),
        menuPortal: (base) => ({ ...base, zIndex: 20 }),
      }}
    />
  );
};
