import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton, CrossIcon } from '@bookla-app/bookla-react-components';

export interface ToastMessageProps {
  id: string;
  message: string;
  /**
   * disables closing or auto-closing. Adds link to reload the page
   */
  reloadPage?: boolean;
  /**
   * default to true
   */
  autoClose?: boolean;
}

export interface ToastProps
  extends Omit<ToastMessageProps, 'id' | 'autoClose'> {
  canClose: boolean;
  close?: () => void;
}

export const Toast: React.FC<ToastProps> = ({
  canClose,
  close,
  message,
  reloadPage,
}) => {
  const intl = useIntl();

  return (
    <div className="relative px-4 py-3 font-medium text-sm inline-block shadow-slot rounded-lg text-center bg-toast-bg text-toast-text">
      {canClose && !!close && (
        <IconButton
          onClick={close}
          ariaLabel={intl.formatMessage({ id: 'modal_close_aria_label' })}
          icon={CrossIcon}
          size="xs"
          className="absolute top-0 right-0 m-0.5"
          theme="transparent-contrast"
        />
      )}
      {message}
      {!!reloadPage && (
        <>
          {' '}
          <button
            className="text-toast-button font-medium"
            onClick={() => window.location.reload()}
          >
            <FormattedMessage id="button_reload" />
          </button>
        </>
      )}
    </div>
  );
};
