import { CompanyResponse, TicketBundle } from '../types/company';
import apiAxios from './apiAxios';

const BASE_ROUTE = '/api/feed/company';
const BASE_ROUTE_V2 = '/api/v2/feed/company';

export const companyApi = {
  getCompanyDetails: (companyId: string): Promise<CompanyResponse> =>
    apiAxios.get(`${BASE_ROUTE_V2}/${companyId}`),
  getTicketBundleDetails: (
    companyId: string,
    bundleId: string
  ): Promise<TicketBundle> =>
    apiAxios.get(`${BASE_ROUTE}/${companyId}/ticketsBundle/${bundleId}`),
};
