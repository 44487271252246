import {
  LogoWithTextIcon,
  Blob1Icon,
  Blob2Icon,
} from '@bookla-app/bookla-react-components';
import { ToastContainer } from 'components/molecules/toastContainer';
import { CookieBanner } from 'components/organisms/cookieBanner';
import { AppStateProvider } from 'contexts/AppStateProvider';
import { CartProvider } from 'contexts/CartProvider/CartProvider';
import { LayoutProvider } from 'contexts/layoutProvider/LayoutProvider';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { isInIframe } from 'utils/isInIframe';
import { PageScroller } from 'utils/scroll';

export const WidgetTemplate: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <LayoutProvider>
      <AppStateProvider>
        <CartProvider>
          <div className="app-container">
            {!isInIframe() && (
              <>
                <div className="hidden sm-lg-logo:block sm:absolute sm:top-7 sm:left-14 xl:left-24">
                  <LogoWithTextIcon />
                </div>
                <div className="pointer-events-none absolute transform origin-top-right scale-30 sm:scale-50 lg:scale-70 xl:scale-100 top-0 right-0 z-0">
                  <Blob1Icon
                    color="fill-rainbow-orange-light"
                    opacity="opacity-20"
                  />
                </div>
                <div className="pointer-events-none absolute transform origin-bottom-left scale-40 sm:scale-60 lg:scale-80 xl:scale-100 bottom-0 left-0 z-0">
                  <Blob2Icon color="fill-rainbow-violet-light" />
                </div>
              </>
            )}
            <div className="app">
              {children}
              <PageScroller />
              <div id="modal-container" />
              <ToastContainer />
              <ReactTooltip
                effect="solid"
                multiline={true}
                className="react-tooltip"
              />
              {!isInIframe() && <CookieBanner />}
            </div>
          </div>
        </CartProvider>
      </AppStateProvider>
    </LayoutProvider>
  );
};
