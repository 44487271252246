import { hasCookieConsent } from 'components/organisms/cookieBanner/utils';
import mixpanel from 'mixpanel-browser';
import { isInIframe } from 'utils/isInIframe';
import { getUrlParams } from 'utils/url';

interface AnalyticProperties {
  [key: string]: string | number | undefined | null;
}

interface QueueTrackItem {
  action: 'track';
  params: [string, AnalyticProperties | undefined];
}

interface QueueIdentifyItem {
  action: 'identify';
  params: [string];
}

type QueueItem =
  | { action: 'init' | 'reset' }
  | QueueTrackItem
  | QueueIdentifyItem;

class AnalyticsAdaptor {
  private queue: QueueItem[] = [];
  private isProcessingQueue = false;

  constructor() {
    this.init();
  }

  private processQueueItem = (item: QueueItem) => {
    switch (item.action) {
      case 'init':
        this._init();
        break;
      case 'track':
        this._track(...item.params);
        break;
      case 'identify':
        this._identify(...item.params);
        break;
      case 'reset':
        this._reset();
        break;
    }
  };

  private processQueue = () => {
    this.isProcessingQueue = true;
    this.queue.forEach((item) => {
      this.processQueueItem(item);
    });
    this.queue = [];
    this.isProcessingQueue = false;
  };

  private resetQueue = () => {
    this.queue = this.queue.filter((item) => item.action === 'init');
  };

  private handleAction = (action: QueueItem, cb: () => void) => {
    const hasConsent = hasCookieConsent('isStatisticsSelected');
    if (hasConsent === undefined || this.isProcessingQueue) {
      this.queue.push(action);
      return;
    }
    if (!hasConsent) {
      this.resetQueue();
      return;
    }
    if (this.queue.length) {
      this.processQueue();
    }

    cb();
  };

  private _init() {
    if (process.env.REACT_APP_ANALYTICS_TOKEN) {
      mixpanel.init(process.env.REACT_APP_ANALYTICS_TOKEN, {
        debug: process.env.REACT_APP_BOOKLA_ENV === 'development',
        cross_site_cookie: true,
        loaded: () => {
          mixpanel.track('loaded', {
            companyId: getUrlParams().companyId,
            isInIframe: isInIframe(),
          });
        },
      });
    }
  }

  private init = () => {
    this.handleAction({ action: 'init' }, () => {
      this._init();
    });
  };

  private _track = (event: string, properties?: AnalyticProperties) => {
    mixpanel.track(event, properties);
  };

  public track = (event: string, properties?: AnalyticProperties) => {
    this.handleAction({ action: 'track', params: [event, properties] }, () => {
      this._track(event, properties);
    });
  };

  private _identify = (userId: string) => {
    mixpanel.identify(userId);
  };

  public identify = (userId: string) => {
    this.handleAction({ action: 'identify', params: [userId] }, () => {
      this._identify(userId);
    });
  };

  private _reset = () => {
    mixpanel.reset();
  };

  public reset = () => {
    this.handleAction({ action: 'reset' }, () => {
      this._reset();
    });
  };
}

export const analyticsAdaptor = new AnalyticsAdaptor();
