import { analytics } from 'utils/analytics';
import { logger } from 'utils/logger';
import { Button, LadySearchingIcon } from '@bookla-app/bookla-react-components';

export const onErrorPage = (error: unknown) => {
  logger.error(error);
  analytics.pageLoaded('pageError');
};

export const ErrorBoundaryPage = () => {
  return (
    <div className="app-container">
      <div className="app">
        <div className="page p-5 md:p-10 justify-center items-center">
          <div className="max-w-sm w-full my-9">
            <LadySearchingIcon fullSize />
          </div>
          <h2 className="mb-6 sm:mb-14 text-center text-xl sm:text-2xl font-medium">
            Something went wrong. Please try again later 🕵🏻‍♀️
          </h2>
          <Button onClick={() => window.location.reload()} size="sm">
            Refresh
          </Button>
        </div>
      </div>
    </div>
  );
};
