import { createContext, useContext, useState } from 'react';

export interface LayoutContext {
  isCookieBannerVisible: boolean;
  setCookieBannerVisible: (isVisible: boolean) => void;
}

const layoutContext = createContext<LayoutContext>({
  isCookieBannerVisible: false,
  setCookieBannerVisible: () => {},
});

export const useLayoutState = () => useContext(layoutContext);

export const LayoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // TODO: move toast message state here from app provider
  const [isCookieBannerVisible, setCookieBannerVisible] = useState(false);

  return (
    <layoutContext.Provider
      value={{
        isCookieBannerVisible,
        setCookieBannerVisible,
      }}
    >
      {children}
    </layoutContext.Provider>
  );
};
